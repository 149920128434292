@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import '~bootstrap-select/dist/css/bootstrap-select.min.css';
:root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3490dc;
  --secondary: #6c757d;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3490dc;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1d68a7;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.8rem;
}

h3, .h3 {
  font-size: 1.575rem;
}

h4, .h4 {
  font-size: 1.35rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6e0f5;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95c5ed;
}

.table-hover .table-primary:hover {
  background-color: #b0d4f1;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0d4f1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7eed8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-success:hover {
  background-color: #b3e8ca;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e9f9;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3d7f5;
}

.table-hover .table-info:hover {
  background-color: #c0ddf6;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffacc;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff6a1;
}

.table-hover .table-warning:hover {
  background-color: #fff8b3;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c6c5;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09593;
}

.table-hover .table-danger:hover {
  background-color: #f4b0af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 157, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-warning:hover {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffe817;
  border-color: #ffe70a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 207, 69, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3490dc;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3490dc;
  text-decoration: none;
}
.btn-link:hover {
  color: #1d68a7;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3490dc;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.44rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.22rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3490dc;
  background-color: #3490dc;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a1cbef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cce3f6;
  border-color: #cce3f6;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3490dc;
  background-color: #3490dc;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.22rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #a1cbef;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3490dc;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3490dc;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1d68a7;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3490dc;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2176bd;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #38c172;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #6cb2eb;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #3f9ae5;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffed4a;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ffe817;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e3342f;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.85rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1b4b72;
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}
.alert-primary hr {
  border-top-color: #b0d4f1;
}
.alert-primary .alert-link {
  color: #113049;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}
.alert-success hr {
  border-top-color: #b3e8ca;
}
.alert-success .alert-link {
  color: #123c24;
}

.alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}
.alert-info hr {
  border-top-color: #c0ddf6;
}
.alert-info .alert-link {
  color: #284257;
}

.alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}
.alert-warning hr {
  border-top-color: #fff8b3;
}
.alert-warning .alert-link {
  color: #5d561b;
}

.alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}
.alert-danger hr {
  border-top-color: #f4b0af;
}
.alert-danger .alert-link {
  color: #4c110f;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3490dc;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1b4b72;
  background-color: #c6e0f5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1b4b72;
  background-color: #b0d4f1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b4b72;
  border-color: #1b4b72;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}

.list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}

.list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3490dc !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2176bd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #38c172 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d995b !important;
}

.bg-info {
  background-color: #6cb2eb !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f9ae5 !important;
}

.bg-warning {
  background-color: #ffed4a !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe817 !important;
}

.bg-danger {
  background-color: #e3342f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c51f1a !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3490dc !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #38c172 !important;
}

.border-info {
  border-color: #6cb2eb !important;
}

.border-warning {
  border-color: #ffed4a !important;
}

.border-danger {
  border-color: #e3342f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3490dc !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1d68a7 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #38c172 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #27864f !important;
}

.text-info {
  color: #6cb2eb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #298fe2 !important;
}

.text-warning {
  color: #ffed4a !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #fde300 !important;
}

.text-danger {
  color: #e3342f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ae1c17 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 768px !important;
  }

  .container {
    min-width: 768px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.v-select {
  position: relative;
  font-family: inherit;
}

.v-select,
.v-select * {
  box-sizing: border-box;
}

/* KeyFrames */
@-webkit-keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}

/** Component States */
/*
 * Disabled
 *
 * When the component is disabled, all interaction
 * should be prevented. Here we modify the bg color,
 * and change the cursor displayed on the interactive
 * components.
 */
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: #f8f8f8;
}

/*
 *  RTL - Right to Left Support
 *
 *  Because we're using a flexbox layout, the `dir="rtl"`
 *  HTML attribute does most of the work for us by
 *  rearranging the child elements visually.
 */
.v-select[dir=rtl] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir=rtl] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir=rtl] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir=rtl] .vs__dropdown-menu {
  text-align: right;
}

/**
    Dropdown Toggle

    The dropdown toggle is the primary wrapper of the component. It
    has two direct descendants: .vs__selected-options, and .vs__actions.

    .vs__selected-options holds the .vs__selected's as well as the
    main search input.

    .vs__actions holds the clear button and dropdown toggle.
 */
.vs__dropdown-toggle {
  appearance: none;
  display: flex;
  padding: 0 0 4px 0;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  white-space: normal;
}

.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}

/* Dropdown Toggle States */
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transform: scale(1);
  transition: transform 150ms cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}

.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(1);
}

.vs--loading .vs__open-indicator {
  opacity: 0;
}

/* Clear Button */
.vs__clear {
  fill: rgba(60, 60, 60, 0.5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}

/* Dropdown Menu */
.vs__dropdown-menu {
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.vs__no-options {
  text-align: center;
}

/* List Items */
.vs__dropdown-option {
  line-height: 1.42857143;
  /* Normalize line height */
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  /* Overrides most CSS frameworks */
  white-space: nowrap;
}
.vs__dropdown-option:hover {
  cursor: pointer;
}

.vs__dropdown-option--highlight {
  background: #5897fb;
  color: #fff;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: rgba(60, 60, 60, 0.5);
}
.vs__dropdown-option--disabled:hover {
  cursor: inherit;
}

/* Selected Tags */
.vs__selected {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  color: #333;
  line-height: 1.4;
  margin: 4px 2px 0px 2px;
  padding: 0 0.25em;
  z-index: 0;
}

.vs__deselect {
  display: inline-flex;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: rgba(60, 60, 60, 0.5);
  text-shadow: 0 1px 0 #fff;
}

/* States */
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--open .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}

/* Search Input */
/**
 * Super weird bug... If this declaration is grouped
 * below, the cancel button will still appear in chrome.
 * If it's up here on it's own, it'll hide it.
 */
.vs__search::-webkit-search-cancel-button {
  display: none;
}

.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration,
.vs__search::-ms-clear {
  display: none;
}

.vs__search,
.vs__search:focus {
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}

.vs__search::placeholder {
  color: inherit;
}

/**
    States
 */
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search:hover {
  cursor: pointer;
}

.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}

/* Loading Spinner */
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 0.9em solid rgba(100, 100, 100, 0.1);
  border-right: 0.9em solid rgba(100, 100, 100, 0.1);
  border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left: 0.9em solid rgba(60, 60, 60, 0.45);
  transform: translateZ(0);
  animation: vSelectSpinner 1.1s infinite linear;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
  opacity: 1;
}

.btn-file {
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}

.fileinput {
  margin-bottom: 9px;
  display: inline-block;
}

.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0px;
  vertical-align: middle;
  cursor: text;
}

.fileinput .thumbnail {
  overflow: hidden;
  display: inline-block;
  margin-bottom: 5px;
  vertical-align: middle;
  text-align: center;
}

.fileinput .thumbnail > img {
  max-height: 100%;
}

.fileinput .btn {
  vertical-align: middle;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

.fileinput.input-group {
  display: table;
}

.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}

.fileinput.input-group > .btn-file {
  z-index: 1;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 2px 2px 0;
}

.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new.input-group .btn-group-sm > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-group-sm > .btn-file.btn {
  border-radius: 0 2px 2px 0;
}

.fileinput-new.input-group .btn-file.btn-lg, .fileinput-new.input-group .btn-group-lg > .btn-file.btn,
.fileinput-new .input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-group-lg > .btn-file.btn {
  border-radius: 0 2px 2px 0;
}

.form-group.has-warning .fileinput .fileinput-preview {
  color: #ffa829;
}

.form-group.has-warning .fileinput .thumbnail {
  border-color: #ff760f;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: #f46963;
}

.form-group.has-error .fileinput .thumbnail {
  border-color: #f24b60;
}

.form-group.has-success .fileinput .fileinput-preview {
  color: #67bd6a;
}

.form-group.has-success .fileinput .thumbnail {
  border-color: #61b555;
}

.input-group-addon:not(:first-child) {
  border-left: 0;
}

/*-------------------------------
    Material Background Colors
--------------------------------*/
.bgm-white {
  background-color: #ffffff !important;
}

.c-white {
  color: #ffffff !important;
}

.bgm-black {
  background-color: #000000 !important;
}

.c-black {
  color: #000000 !important;
}

.bgm-brown {
  background-color: #795548 !important;
}

.c-brown {
  color: #795548 !important;
}

.bgm-pink {
  background-color: #E91E63 !important;
}

.c-pink {
  color: #E91E63 !important;
}

.bgm-red {
  background-color: #f1453d !important;
}

.c-red {
  color: #f1453d !important;
}

.bgm-blue {
  background-color: #2196F3 !important;
}

.c-blue {
  color: #2196F3 !important;
}

.bgm-purple {
  background-color: #BA68C8 !important;
}

.c-purple {
  color: #BA68C8 !important;
}

.bgm-deeppurple {
  background-color: #673AB7 !important;
}

.c-deeppurple {
  color: #673AB7 !important;
}

.bgm-lightblue {
  background-color: #03A9F4 !important;
}

.c-lightblue {
  color: #03A9F4 !important;
}

.bgm-cyan {
  background-color: #00BCD4 !important;
}

.c-cyan {
  color: #00BCD4 !important;
}

.bgm-teal {
  background-color: #009688 !important;
}

.c-teal {
  color: #009688 !important;
}

.bgm-green {
  background-color: #4CAF50 !important;
}

.c-green {
  color: #4CAF50 !important;
}

.bgm-lightgreen {
  background-color: #8BC34A !important;
}

.c-lightgreen {
  color: #8BC34A !important;
}

.bgm-lime {
  background-color: #CDDC39 !important;
}

.c-lime {
  color: #CDDC39 !important;
}

.bgm-yellow {
  background-color: #607d8b !important;
}

.c-yellow {
  color: #607d8b !important;
}

.bgm-amber {
  background-color: #FFC107 !important;
}

.c-amber {
  color: #FFC107 !important;
}

.bgm-orange {
  background-color: #FF9800 !important;
}

.c-orange {
  color: #FF9800 !important;
}

.bgm-deeporange {
  background-color: #FF5722 !important;
}

.c-deeporange {
  color: #FF5722 !important;
}

.bgm-gray {
  background-color: #9E9E9E !important;
}

.c-gray {
  color: #9E9E9E !important;
}

.bgm-bluegray {
  background-color: #607D8B !important;
}

.c-bluegray {
  color: #607D8B !important;
}

.bgm-indigo {
  background-color: #3F51B5 !important;
}

.c-indigo {
  color: #3F51B5 !important;
}

/*-------------------------
    Background Colors
--------------------------*/
.bg-black-trp {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

/*-------------------------
    Border
--------------------------*/
.b-0 {
  border: 0 !important;
}

/*-------------------------
    width
--------------------------*/
.w-100 {
  width: 100% !important;
}

/*-------------------------
    Border Radius
--------------------------*/
.brd-2 {
  border-radius: 2px;
}

/*------------------------------------------------------------------------------------------------------------
    Media - Overriding the Media object to 3.2 version in order to prevent issues like text overflow.
--------------------------------------------------------------------------------------------------------------*/
.media {
  overflow: visible;
}

.media:before,
.media:after {
  content: " ";
  display: table;
}

.media:after {
  clear: both;
}

.media:before,
.media:after {
  content: " ";
  display: table;
}

.media:after {
  clear: both;
}

.media > .pull-left {
  padding-right: 15px;
}

.media > .pull-right {
  padding-left: 15px;
}

.media-heading {
  font-size: 14px;
  margin-bottom: 10px;
}

.media-body {
  zoom: 1;
  display: block;
  width: auto;
}

.media-object {
  border-radius: 2px;
}

.close {
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-weight: normal;
  text-shadow: none;
}

.close:hover {
  color: inherit;
  opacity: 1;
  filter: alpha(opacity=100);
}

.dl-horizontal dt {
  text-align: left;
}

*,
button,
input,
i,
a {
  -webkit-font-smoothing: antialiased;
}

*,
*:active,
*:hover,
*:focus {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
  overflow-x: hidden;
  -ms-overflow-style: none;
}

html:not(.ie9) body {
  overflow: visible;
}

html.ismobile * {
  cursor: pointer;
}

html,
body {
  min-height: 100vh;
}

body {
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}

audio,
video {
  outline: none;
}

p {
  margin-bottom: 20px;
}

.page-break {
  display: block;
  clear: both;
  page-break-after: always;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small {
  font-size: 12px;
}

#main {
  position: relative;
  padding-top: 110px;
  min-height: 100vh;
}

body.pdf #main {
  padding-top: 0;
}

.sidebar-toggled #main {
  height: 100vh;
  overflow: hidden;
}

@media (min-width: 767px) {
  .container {
    padding-left: 75px;
    padding-right: 75px;
  }
}
.container.container-alt {
  max-width: 1170px;
}

#content.content-alt {
  max-width: 1200px;
  margin: 0 auto;
}

.clist {
  list-style: none;
}

.clist > li:before {
  font-family: "Material-Design-Iconic-Font";
  margin: 0 10px 0 -20px;
  vertical-align: middle;
}

.clist.clist-angle > li:before {
  content: "";
}

.clist.clist-check > li:before {
  content: "";
}

.clist.clist-star > li:before {
  content: "";
}

/*--------------------------------------------------
    Common header classes & IDs
    Do not remove this
---------------------------------------------------*/
.h-inner {
  list-style: none;
  padding: 17px 0;
  margin-bottom: 0;
  position: relative;
}

.h-inner > li:not(.pull-right) {
  float: left;
}

.hi-client-logos {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 50px;
  text-align: center;
}
.hi-client-logos img {
  height: 100%;
  margin-right: 10px;
}

.hi-logo {
  padding-top: 5px;
}

.hi-logo a {
  color: #000;
  display: block;
  font-size: 16px;
}

.hi-trigger {
  position: relative;
  margin-left: -5px;
  cursor: pointer;
  display: none !important;
}

.hi-trigger:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.22);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  z-index: 0;
  margin-top: -22px;
  margin-left: -22px;
}

.hi-trigger.toggled:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.hi-menu {
  list-style: none;
  padding: 0;
}

.hi-menu > li {
  display: inline-block;
  margin: 0 1px;
  vertical-align: top;
  min-width: 50px;
}

@media (max-width: 767px) {
  .hi-menu > li {
    position: static !important;
  }
}
.hi-menu > li .dropdown-menu {
  top: -5px;
}

.hi-menu > li .dropdown-menu-lg {
  padding: 0;
}

.hi-menu > li .dropdown-menu-lg .lg-body {
  min-height: 350px;
  overflow-x: hidden;
}

.hi-menu > li > a {
  color: #6c6f74;
  display: block;
  text-align: center;
  z-index: 1;
  position: relative;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  border-radius: 2px;
}

.hi-menu > li > a > .him-icon {
  font-size: 24px;
  line-height: 36px;
}

.hi-menu > li > a > .him-label {
  line-height: 35px;
  white-space: nowrap;
  padding: 0 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.hi-menu > li > a > .him-counts {
  color: #fff;
  position: absolute;
  font-style: normal;
  background: #f1453d;
  padding: 1px 5px;
  border-radius: 2px;
  right: 7px;
  top: -3px;
  font-size: 10px;
  line-height: 15px;
}

.hi-menu > li.toggled > a,
.hi-menu > li:hover > a {
  background-color: rgba(0, 0, 0, 0.08);
}

@media (max-width: 767px) {
  .hi-menu .dropdown-menu-lg {
    width: calc(100% - 28px) !important;
  }

  .hi-menu .dropdown-menu {
    right: 14px;
    top: 55px !important;
  }
}
.him-notification:before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  background: url(/assets/notifications.png) no-repeat center;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(0) rotate(-180deg);
  -ms-transform: scale(0) rotate(-180deg);
  -o-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
  opacity: 0;
  filter: alpha(opacity=0);
  top: 42px;
}

.him-notification.empty:before {
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Full Screen */
:-webkit-full-screen [data-ma-action=fullscreen] {
  display: none;
}

:-moz-full-screen [data-ma-action=fullscreen] {
  display: none;
}

:-ms-fullscreen [data-ma-action=fullscreen] {
  display: none;
}

:full-screen [data-ma-action=fullscreen] {
  display: none;
}

:fullscreen [data-ma-action=fullscreen] {
  display: none;
}

/* ----------------------------- End common header classes and IDs------------------------------------- */
/*--------------------------------------------------
    For header type 1 only
    You may remove these if you opt header 2
---------------------------------------------------*/
#header {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  min-height: 70px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  z-index: 11;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
}

@media (max-width: 767px) {
  #header {
    padding: 0px 8px;
  }
}
#header .hi-logo a {
  padding: 7px 10px;
}

#header .ma-backdrop {
  position: absolute;
}

@media (max-width: 1279px) {
  #header .hi-trigger {
    display: inline-block !important;
  }
}
#header.search-toggled .h-search-wrap {
  top: 0;
  opacity: 1;
  filter: alpha(opacity=100);
}

.h-search-wrap {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  height: 70px;
  background: #fff;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 10;
}

.h-search-wrap input[type=text] {
  border: 0;
  height: 40px;
  padding: 0 10px 0 55px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #efefef;
  width: 100%;
}

.h-search-wrap .hsw-close {
  position: absolute;
  top: 15px;
  font-size: 23px;
  font-style: normal;
  width: 45px;
  text-align: center;
  border-radius: 2px 0px 0px 2px;
  cursor: pointer;
  left: 15px;
  height: 40px;
  padding-top: 9px;
}

.h-search-wrap .hsw-close:hover {
  background-color: #e3e3e3;
}

@media (max-width: 767px) {
  .h-search-wrap .hsw-close {
    right: 7px;
  }
}
.hsw-inner {
  position: relative;
  padding: 15px;
  max-width: 700px;
  display: block;
  margin: 0 auto;
}

/* ----------------------------- End header type 1 ------------------------------------- */
/*--------------------------------------------------
    For Header type 2 only
    You may remove these if you opt header 1
---------------------------------------------------*/
#header-alt {
  position: relative;
  margin-bottom: -50px;
  z-index: 10;
  background-color: #fff;
}

#header-alt:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 49px;
  z-index: -1;
  border-bottom: 1px solid #dcddde;
  border-top: 1px solid #dcddde;
}

#header-alt .hi-trigger {
  top: -7px;
  left: -15px;
}

@media (max-width: 991px) {
  #header-alt .hi-trigger {
    display: inline-block !important;
  }
}
#header-alt .ma-backdrop {
  position: absolute;
}

#header-alt .ha-search {
  margin-bottom: 25px;
  padding-right: 25px;
}

#header-alt .ha-search input[type=text] {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  padding: 6px 0 6px 30px;
}

#header-alt .ha-search input[type=text]::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

#header-alt .ha-search input[type=text]:-ms-input-placeholder {
  color: #fff;
}

#header-alt .ha-search input[type=text]::-webkit-input-placeholder {
  color: #fff;
}

#header-alt .ha-search input[type=text].ie9-placeholder {
  color: #fff !important;
}

#header-alt .ha-search .fg-line {
  max-width: 500px;
  position: relative;
}

#header-alt .ha-search .fg-line:after {
  background: #607d8b;
}

#header-alt .ha-search .fg-line:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  left: 0;
  bottom: 1px;
  color: #fff;
  font-size: 22px;
}

.ha-menu > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ha-menu > ul > li {
  display: inline-block;
  vertical-align: top;
}

.ha-menu > ul > li:not(.active) > *:not(ul) {
  color: #6d7074;
}

.ha-menu > ul > li.active > a {
  color: #607d8b;
  box-shadow: inset 0px -3px 0 0px #607d8b;
}

.ha-menu > ul > li > a {
  text-transform: uppercase;
  padding: 15px 12px;
  display: block;
}

.ha-menu > ul > li .dropdown-menu {
  min-width: 100%;
}

.s-profile > a {
  display: block;
  height: 129px;
  margin-bottom: 5px;
  width: 100%;
  background: url(/assets/profile-menu.png) no-repeat left top;
  background-size: 100%;
}

.s-profile > a .sp-pic {
  padding: 12px;
}

.s-profile > a .sp-pic > img {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.14);
  box-sizing: content-box;
}

.s-profile > a .sp-info {
  background: rgba(0, 0, 0, 0.37);
  padding: 7px 14px;
  color: #fff;
  margin-top: 20px;
  position: relative;
}

.s-profile > a .sp-info > i {
  font-size: 19px;
  line-height: 100%;
  position: absolute;
  right: 15px;
  top: 7px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.s-profile .main-menu {
  display: none;
  margin: 0 0 0;
}

.s-profile.toggled .sp-info > i {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.main-menu {
  list-style: none;
  padding-left: 0;
  margin: 20px 0 0 0;
}

.main-menu a {
  -webkit-transition: color;
  -o-transition: color;
  transition: color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.main-menu a:hover,
.main-menu .active > a,
.main-menu a.active {
  color: #262626;
}

.main-menu > li > a {
  padding: 14px 20px 14px 65px;
  display: block;
  font-weight: 500;
  position: relative;
  color: #4C4C4C;
}

.main-menu > li > a > i {
  position: absolute;
  left: 25px;
  font-size: 18px;
  top: 2px;
  width: 25px;
  text-align: center;
  padding: 13px 0;
}

.main-menu > li > a:hover,
.main-menu > li.active > a {
  background-color: #f7f7f7;
}

.sub-menu > a {
  position: relative;
}

.sub-menu > a:before,
.sub-menu > a:after {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  font-family: "Material-Design-Iconic-Font";
  font-size: 17px;
  right: 15px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.sub-menu > a:before {
  content: "";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.sub-menu > a:after {
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.sub-menu.toggled > a:before {
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.sub-menu.toggled > a:after {
  content: "";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.sub-menu ul {
  list-style: none;
  display: none;
  padding: 0;
}

.sub-menu ul > li > a {
  padding: 8px 20px 8px 65px;
  font-weight: 500;
  display: block;
  color: #989898;
}

.sub-menu ul > li:first-child > a {
  padding-top: 14px;
}

.sub-menu ul > li:last-child > a {
  padding-bottom: 16px;
}

.sub-menu ul > li ul {
  font-size: 12px;
  margin: 10px 0;
  background-color: #F7F7F7;
}

.sub-menu.active > ul {
  display: block;
}

/*-------------------------
    For Stupid IE9
--------------------------*/
@media (max-width: 1279px) {
  .ie9 #sidebar {
    display: none;
  }

  .ie9 #sidebar.toggled {
    display: block;
  }
}
.ie9 .sidebar-alt {
  display: none;
}

.ie9 .sidebar-alt.toggled {
  display: block;
}

.dropdown:not([data-animation]) .dropdown-menu {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.dropdown-menu {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  border-radius: 3px;
  top: -1px;
  margin: 0;
  border: 0;
}

.dropdown-menu > li > a {
  padding: 10px 20px;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.dropdown-menu[class*=bgm-] > li > a {
  color: #fff;
}

.dropup .dropdown-menu {
  bottom: -2px;
}

.dm-icon > li > a > .zmdi {
  line-height: 100%;
  vertical-align: top;
  font-size: 18px;
  width: 28px;
}

.dropdown-menu-lg {
  width: 300px;
}

.dropdown-header {
  padding: 3px 17px;
  margin-top: 10px;
  color: #b1b1b1;
  text-transform: uppercase;
  font-weight: normal;
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.list-group {
  margin-bottom: 0;
}

.list-group .list-group-item {
  border: 0;
  margin: 0;
  padding: 15px 30px;
}

.list-group .list-group-item > .checkbox.pull-left {
  margin: 0;
}

.list-group.lg-odd-black .list-group-item:nth-child(odd) {
  background-color: #f9f9f9;
}

.list-group.lg-even-black .list-group-item:nth-child(even) {
  background-color: #f9f9f9;
}

.lg-header {
  text-align: center;
  padding: 15px 10px 13px;
  line-height: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #F0F0F0;
  font-weight: 500;
  color: #4C4C4C;
  margin-bottom: 10px;
}

.lg-header .actions {
  position: absolute;
  top: 5px;
  right: 10px;
}

.lgi-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.lgi-heading {
  color: #000;
  margin-bottom: 4px;
  display: block;
}

.lgi-heading,
.lgi-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lgi-text {
  display: block;
  font-size: 12px;
  color: #777777;
}

.lgi-text:not(:last-child) {
  margin-bottom: 4px;
}

.lgi-checkbox {
  margin-top: 8px;
  margin-bottom: 0;
}

.lgi-attrs {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lgi-attrs > li {
  display: inline-block;
  border: 1px solid #f0f0f0;
  margin: 2px 2px 2px 0;
  padding: 2px 5px;
  font-size: 12px;
  color: #777777;
}

.lgi-attrs > li > a {
  display: block;
}

.progress {
  box-shadow: none;
  border-radius: 0;
  height: 5px;
  margin-bottom: 0;
}

.progress .progress-bar {
  box-shadow: none;
}

#chat {
  padding: 20px 0 5px;
  width: 280px;
  right: -300px;
  box-shadow: 0 0 20px rgba(14, 18, 21, 0.38);
}

#chat.toggled {
  right: 0;
}

#chat .chat-search {
  padding: 20px 20px 15px 20px;
}

#chat .chat-search .form-control {
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
  background-color: transparent;
}

#chat .chat-search .form-control:focus {
  padding: 0 30px 0 0;
}

#chat .chat-search .form-control:focus + .zmdi-search {
  left: calc(100% - 15px);
}

#chat .chat-search .form-control,
#chat .chat-search .zmdi-search {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

#chat .chat-search .zmdi-search {
  position: absolute;
  left: 0;
  bottom: 6px;
  font-size: 20px;
}

#chat .lg-body {
  height: calc(100% - 70px);
}

/*------------------------------
    Chat Status Icons
-------------------------------*/
[class*=chat-status] {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -3px;
  right: 12px;
  border: 2px solid #FFF;
}

/* Simple Mixin */
.chat-status-online {
  box-shadow: 0 0 0 1px #1EC01E;
  background: #1EC01E;
}

.chat-status-offline {
  box-shadow: 0 0 0 1px #E73F3F;
  background: #E73F3F;
}

.chat-status-busy {
  box-shadow: 0 0 0 1px #FFA500;
  background: #FFA500;
}

.tab-nav {
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  overflow: none;
  width: 100%;
}

.tab-nav li {
  display: inline-block;
  vertical-align: top;
}
.tab-nav li:first-child > a {
  border-left: none;
}
.tab-nav li:last-child > a {
  border-right: none;
}

.tab-nav li > a {
  border-radius: 0 !important;
  display: inline-block;
  color: #7a7a7a;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  transition: all;
  transition-duration: 250ms;
  width: 100%;
}

@media (min-width: 768px) {
  .tab-nav li > a {
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .tab-nav li > a {
    padding: 15px 8px;
  }
}
.tab-nav li.active > a {
  color: #000;
}

.tab-nav li.active > a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.tab-nav.tab-nav-right {
  text-align: right;
}

.tab-nav.tn-justified > li {
  display: table-cell;
  width: 1%;
  text-align: center;
}

.tab-nav.tn-icon > li .zmdi {
  font-size: 22px;
  line-height: 100%;
  min-height: 25px;
}

.tab-nav:not([data-tab-color]) > li > a:after {
  background: #2196F3;
}

.tab-nav[data-tab-color=green] > li > a:after {
  background: #4CAF50;
}

.tab-nav[data-tab-color=red] > li > a:after {
  background: #f1453d;
}

.tab-nav[data-tab-color=teal] > li > a:after {
  background: #009688;
}

.tab-nav[data-tab-color=amber] > li > a:after {
  background: #FFC107;
}

.tab-nav[data-tab-color=black] > li > a:after {
  background: #000000;
}

.tab-nav[data-tab-color=cyan] > li > a:after {
  background: #00BCD4;
}

.tab-nav[data-tab-color=dark-teal] > li > a:after {
  background: #607d8b;
}

.tab-content {
  padding: 20px 0;
}

.card {
  position: relative;
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  border-radius: 2px;
}

.card .card-header {
  position: relative;
  display: block;
  padding: 26px 30px;
  border-radius: 2px 2px 0 0;
}

.card .card-header h2 {
  margin: 0;
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
}
.card .card-header h2 .zmdi-help-outline {
  cursor: pointer;
}

.card .card-header h2 small {
  display: block;
  margin-top: 8px;
  color: #AEAEAE;
  line-height: 160%;
}

.card .card-header.ch-alt:not([class*=bgm-]) {
  background-color: #f7f7f7;
}

.card .card-header .actions {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 15px;
}

.card .card-header .btn-float {
  right: 25px;
  bottom: -23px;
  z-index: 1;
}

.card .card-header[class*=bgm-] h2 {
  color: #fff;
}

.card .card-header[class*=bgm-] h2 small {
  color: rgba(255, 255, 255, 0.7);
}

.card .card-body.card-padding {
  padding: 26px 30px;
}

.card .card-body.card-padding-sm {
  padding: 15px;
}

.card-header:not(.ch-alt):not([class*=bgm-]) + .card-padding {
  padding-top: 0;
}

.card-light .card-header h2,
.card-light .card-header h2 small {
  color: #fff;
}

.chart-edge {
  margin: 20px -8px 0 -10px;
  overflow: hidden;
}

.chart-edge .flot-chart {
  bottom: -14px;
}

.charts-row {
  margin-top: 50px;
  margin-bottom: 20px;
}

.mini-charts-item {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
}

.mini-charts-item .chart {
  padding: 21px 18px 0;
  float: left;
}

.mini-charts-item .chart.chart-pie {
  margin: 0 20px;
  padding: 14px 11px;
}

.mini-charts-item .count {
  overflow: hidden;
  color: rgba(255, 255, 255, 0.9);
  padding: 16px 12px;
}

.mini-charts-item .count > h2 {
  margin: 0;
  line-height: 100%;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
}

.mini-charts-item .count > small {
  margin-bottom: 2px;
  display: block;
}

.mini-charts-item .count > h2,
.mini-charts-item .count > small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mini-charts-item > .clearfix,
.mini-charts-item > .dl-horizontal dd,
.mini-charts-item > .container,
.mini-charts-item > .container-fluid,
.mini-charts-item > .container-sm,
.mini-charts-item > .container-md,
.mini-charts-item > .container-lg,
.mini-charts-item > .container-xl,
.mini-charts-item > .row,
.mini-charts-item > .form-horizontal .form-group,
.mini-charts-item > .btn-toolbar,
.mini-charts-item > .btn-group-vertical > .btn-group,
.mini-charts-item > .nav,
.mini-charts-item > .navbar,
.mini-charts-item > .navbar-header,
.mini-charts-item > .navbar-collapse,
.mini-charts-item > .pager,
.mini-charts-item > .panel-body,
.mini-charts-item > .modal-header,
.mini-charts-item > .modal-footer {
  position: relative;
  z-index: 1;
}

.mini-charts-item:before {
  -webkit-transition: width;
  -o-transition: width;
  transition: width;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  content: "";
  width: 105px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
}

.mini-charts-item:hover .count {
  color: #fff !important;
}

.mini-charts-item:hover:before {
  width: 100%;
}

/*------------------------------
    Sparkline Tooltip
-------------------------------*/
#jqstooltip {
  min-width: 21px;
  min-height: 23px;
  text-align: center;
  border: 0;
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

#jqstooltip .jqsfield {
  font-size: 12px;
  font-weight: 700;
  font-family: inherit;
  text-align: center;
  color: #333;
}

#jqstooltip .jqsfield > span {
  display: none;
}

/*------------------------------
    Easy Pie Charts
-------------------------------*/
.epc-item {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  position: relative;
  margin-bottom: 30px;
  padding: 30px 20px;
  text-align: center;
}

.easy-pie {
  display: inline-block;
  position: relative;
  padding: 0 0 10px;
}

.easy-pie .percent {
  position: absolute;
  font-weight: 300;
  width: 100%;
  line-height: 100%;
  left: 4px;
  color: #fff;
}

.easy-pie .percent:after {
  content: "%";
}

.easy-pie.main-pie .percent {
  margin-top: 49px;
  font-size: 50px;
  text-align: center;
}

.easy-pie.main-pie .percent:after {
  font-size: 30px;
}

.easy-pie.main-pie .pie-title {
  color: #fff;
}

.easy-pie:not(.main-pie) .percent {
  font-size: 24px;
  margin-top: 33px;
}

.easy-pie:not(.main-pie) .percent:after {
  font-size: 20px;
}

.easy-pie .pie-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -3px;
  left: 0;
}

/*------------------------------
    Recent Items Table Chart
-------------------------------*/
#recent-items-chart {
  width: calc(100% + 19px);
  height: 150px;
  margin: -20px -10px 0;
  bottom: -10px;
}

/*------------------------------
    Flot Chart
-------------------------------*/
[class*=flot-chart] {
  width: 100%;
  display: block;
}

.flot-chart {
  height: 250px;
}

.flot-chart-pie {
  height: 300px;
}

@media (min-width: 768px) {
  .flot-chart-pie {
    margin-bottom: 20px;
  }
}
.flot-tooltip,
#flotTip {
  position: absolute;
  color: #333;
  display: none;
  font-size: 12px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 3px 10px;
  background-color: #fff;
  z-index: 99999;
}

[class*=flc-] {
  text-align: center;
  margin: 20px 0 -5px;
}

[class*=flc-] table {
  display: inline-block;
}

[class*=flc-] .legendColorBox > div {
  border: #fff !important;
}

[class*=flc-] .legendColorBox > div > div {
  border-radius: 50%;
}

[class*=flc-] .legendLabel {
  padding: 0 8px 0 3px;
}

/*------------------------------
    Maps
-------------------------------*/
.map-sm {
  width: 100%;
  height: 220px;
}

.map-rg {
  width: 100%;
  height: 350px;
}

.dw-item {
  position: relative;
  min-height: 400px;
  margin-bottom: 30px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.dwi-header {
  position: relative;
}

.dwi-header-img {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 155px;
}

.dw-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.dwih-title {
  padding: 12px 20px;
  position: absolute;
  width: 100%;
  left: 0;
}

/*-------------------------
   Site Visits
--------------------------*/
#site-visits .dwi-header {
  padding-bottom: 38px;
  margin-bottom: 12px;
  background-color: rgba(255, 255, 255, 0.05);
}

#site-visits .dwi-header canvas {
  width: 100% !important;
}

#site-visits .dwih-title {
  bottom: 0;
  color: #fff;
}

#site-visits .sv-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.05);
}

#site-visits .sv-item small {
  color: rgba(255, 255, 255, 0.7);
}

#site-visits .sv-item h3 {
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}

#site-visits .sv-item .pull-right {
  margin-top: 5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

/*-------------------------
    Best Selling Item
--------------------------*/
#best-selling {
  background-color: #fff;
}

#best-selling .dwi-header .dwih-title {
  padding-bottom: 30px;
  top: 0;
  color: #fff;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99000000", endColorstr="#00000000", GradientType=0);
}

#best-selling .dwi-header .bs-main {
  padding: 15px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#99000000", GradientType=0);
}

#best-selling .dwi-header .bs-main > h2 {
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0 0 0;
  line-height: 100%;
  color: #fff;
}

/*-------------------------
    Weather
--------------------------*/
#weather-widget {
  color: #fff;
  padding: 20px 20px 0;
}

#weather-widget .weather-status {
  font-size: 40px;
  line-height: 100%;
}

#weather-widget .weather-icon {
  text-align: center;
  margin-top: 10px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  /* Weather Icons */
}

#weather-widget .weather-icon.wi-0 {
  background-image: url("/assets/icons/weather/0.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-0 {
    background-image: url("/assets/icons/weather/0@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-1 {
  background-image: url("/assets/icons/weather/1.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-1 {
    background-image: url("/assets/icons/weather/1@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-2 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-2 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-3 {
  background-image: url("/assets/icons/weather/3.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-3 {
    background-image: url("/assets/icons/weather/3@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-4 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-4 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-5 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-5 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-6 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-6 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-7 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-7 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-8 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-8 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-9 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-9 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-10 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-10 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-11 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-11 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-12 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-12 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-13 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-13 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-14 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-14 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-15 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-15 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-16 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-16 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-17 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-17 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-18 {
  background-image: url("/assets/icons/weather/18.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-18 {
    background-image: url("/assets/icons/weather/18@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-19 {
  background-image: url("/assets/icons/weather/19.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-19 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-20 {
  background-image: url("/assets/icons/weather/19.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-20 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-21 {
  background-image: url("/assets/icons/weather/19.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-21 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-22 {
  background-image: url("/assets/icons/weather/19.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-22 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-23 {
  background-image: url("/assets/icons/weather/19.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-23 {
    background-image: url("/assets/icons/weather/19@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-24 {
  background-image: url("/assets/icons/weather/24.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-24 {
    background-image: url("/assets/icons/weather/24@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-25 {
  background-image: url("/assets/icons/weather/24.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-25 {
    background-image: url("/assets/icons/weather/24@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-26 {
  background-image: url("/assets/icons/weather/26.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-26 {
    background-image: url("/assets/icons/weather/26@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-27 {
  background-image: url("/assets/icons/weather/27.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-27 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-28 {
  background-image: url("/assets/icons/weather/28.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-28 {
    background-image: url("/assets/icons/weather/28@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-29 {
  background-image: url("/assets/icons/weather/27.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-29 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-30 {
  background-image: url("/assets/icons/weather/28.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-30 {
    background-image: url("/assets/icons/weather/28@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-31 {
  background-image: url("/assets/icons/weather/31.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-31 {
    background-image: url("/assets/icons/weather/31@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-32 {
  background-image: url("/assets/icons/weather/32.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-32 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-33 {
  background-image: url("/assets/icons/weather/31.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-33 {
    background-image: url("/assets/icons/weather/31@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-34 {
  background-image: url("/assets/icons/weather/32.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-34 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-35 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-35 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-36 {
  background-image: url("/assets/icons/weather/32.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-36 {
    background-image: url("/assets/icons/weather/32@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-37 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-37 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-38 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-38 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-39 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-39 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-40 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-40 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-41 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-41 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-42 {
  background-image: url("/assets/icons/weather/9.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-42 {
    background-image: url("/assets/icons/weather/9@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-43 {
  background-image: url("/assets/icons/weather/5.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-43 {
    background-image: url("/assets/icons/weather/5@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-44 {
  background-image: url("/assets/icons/weather/27.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-44 {
    background-image: url("/assets/icons/weather/27@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-45 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-45 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-46 {
  background-image: url("/assets/icons/weather/18.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-46 {
    background-image: url("/assets/icons/weather/18@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-icon.wi-47 {
  background-image: url("/assets/icons/weather/2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #weather-widget .weather-icon.wi-47 {
    background-image: url("/assets/icons/weather/2@2x.png");
    background-size: 125px 125px;
  }
}
#weather-widget .weather-info {
  list-style: none;
  padding: 0;
  margin: 3px 0 0 0;
}

#weather-widget .weather-info > li {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.39);
  padding: 2px 10px 3px;
  margin-right: 5px;
}

#weather-widget .dw-footer {
  background: rgba(0, 0, 0, 0.04);
  padding: 10px 20px;
}

#weather-widget .weather-list {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#weather-widget .weather-list > span {
  margin-right: 7px;
  display: inline-block;
  line-height: 40px;
  vertical-align: top;
}

#weather-widget .weather-list > span.weather-list-icon {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

#weather-widget .weather-list > span.weather-list-icon.wi-0 {
  background-image: asset-url("icons/weather/0.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-1 {
  background-image: asset-url("icons/weather/1.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-2 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-3 {
  background-image: asset-url("icons/weather/3.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-4 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-5 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-6 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-7 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-8 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-9 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-10 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-11 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-12 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-13 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-14 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-15 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-16 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-17 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-18 {
  background-image: asset-url("icons/weather/18.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-19 {
  background-image: asset-url("icons/weather/19.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-20 {
  background-image: asset-url("icons/weather/19.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-21 {
  background-image: asset-url("icons/weather/19.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-22 {
  background-image: asset-url("icons/weather/19.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-23 {
  background-image: asset-url("icons/weather/19.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-24 {
  background-image: asset-url("icons/weather/24.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-25 {
  background-image: asset-url("icons/weather/24.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-26 {
  background-image: asset-url("icons/weather/26.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-27 {
  background-image: asset-url("icons/weather/27.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-28 {
  background-image: asset-url("icons/weather/28.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-29 {
  background-image: asset-url("icons/weather/27.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-30 {
  background-image: asset-url("icons/weather/28.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-31 {
  background-image: asset-url("icons/weather/31.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-32 {
  background-image: asset-url("icons/weather/32.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-33 {
  background-image: asset-url("icons/weather/31.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-34 {
  background-image: asset-url("icons/weather/32.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-35 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-36 {
  background-image: asset-url("icons/weather/32.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-37 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-38 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-39 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-40 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-41 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-42 {
  background-image: asset-url("icons/weather/9.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-43 {
  background-image: asset-url("icons/weather/5.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-44 {
  background-image: asset-url("icons/weather/27.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-45 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-46 {
  background-image: asset-url("icons/weather/18.png");
}

#weather-widget .weather-list > span.weather-list-icon.wi-47 {
  background-image: asset-url("icons/weather/2.png");
}

#weather-widget .weather-list > span > i {
  line-height: 100%;
  font-size: 39px;
}

/*-------------------------
    Profile View
--------------------------*/
.profile-view {
  text-align: center;
}

.profile-view .pv-header {
  position: relative;
  height: 145px;
  width: 100%;
  background-image: asset-url("headers/sm/4.png");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.profile-view .pv-header > .pv-main {
  border-radius: 50%;
  width: 130px;
  position: absolute;
  height: 130px;
  bottom: -50px;
  left: 50%;
  margin-left: -65px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.profile-view .pv-body {
  margin-top: 70px;
  padding: 0 20px 20px;
}

.profile-view .pv-body > h2 {
  margin: 0;
  line-height: 100%;
  font-size: 20px;
  font-weight: 400;
}

.profile-view .pv-body > small {
  display: block;
  color: #8E8E8E;
  margin: 10px 0 15px;
}

.profile-view .pv-body .pv-contact,
.profile-view .pv-body .pv-follow {
  padding: 0;
  list-style: none;
}

.profile-view .pv-body .pv-contact > li,
.profile-view .pv-body .pv-follow > li {
  display: inline-block;
}

.profile-view .pv-body .pv-contact > li {
  margin: 0 5px;
}

.profile-view .pv-body .pv-contact > li > .zmdi {
  line-height: 100%;
  vertical-align: text-bottom;
  font-size: 22px;
}

.profile-view .pv-body .pv-follow-btn {
  padding: 7px 20px;
  background: #00BCD4;
  color: #fff;
  border-radius: 3px;
  text-transform: uppercase;
  max-width: 200px;
  width: 100%;
  display: inline-block;
}

.profile-view .pv-body .pv-follow-btn:hover {
  background: #00a5bb;
}

.profile-view:hover .pv-main {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

/*-------------------------
    Picture List
--------------------------*/
.picture-list .pl-body {
  padding: 2px;
}

.picture-list .pl-body [class*=col-] {
  padding: 2px;
}

.picture-list .pl-body [class*=col-] > a {
  display: block;
}

.picture-list .pl-body [class*=col-] > a img {
  width: 100%;
}

.picture-list .pl-body:before,
.picture-list .pl-body:after {
  content: " ";
  display: table;
}

.picture-list .pl-body:after {
  clear: both;
}

.picture-list .pl-body:before,
.picture-list .pl-body:after {
  content: " ";
  display: table;
}

.picture-list .pl-body:after {
  clear: both;
}

/*-------------------------
    Social
--------------------------*/
.go-social .card-body {
  padding: 0 15px 20px;
}

.go-social .card-body [class*=col-] {
  padding: 12px;
}

.go-social .card-body [class*=col-] img {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.go-social .card-body [class*=col-]:hover img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

/*-------------------------
    Rating
--------------------------*/
.rating-list {
  padding: 0 0 20px;
}

.rating-list .rl-star {
  margin-top: 10px;
  margin-bottom: 4px;
}

.rating-list .rl-star .zmdi {
  font-size: 20px;
  color: #ccc;
}

.rating-list .rl-star .zmdi.active {
  color: #FF9800;
}

.rating-list .media .zmdi-star {
  line-height: 100%;
  font-size: 22px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 6px;
  color: #FF9800;
}

.rating-list .media .media-body {
  padding: 7px 10px 0 5px;
}

/*-------------------------
    Calendar
--------------------------*/
.cwh-year {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
}

.cwh-day {
  font-size: 25px;
  line-height: 100%;
  color: #fff;
}

/*-------------------------
    Event List
--------------------------*/
.event-date {
  width: 70px;
  text-align: center;
  border-radius: 2px;
  padding: 6px 5px;
  margin-top: -3px;
  position: relative;
  color: #fff;
}

.ed-day {
  display: block;
  font-size: 20px;
  line-height: 100%;
}

.ed-month-time {
  font-size: 12px;
  display: block;
  margin-top: 1px;
  line-height: 100%;
}

.table {
  margin-bottom: 0;
}

.table > thead > tr > th {
  background-color: #fff;
  vertical-align: middle;
  font-weight: 500;
  color: #6d7074;
  border-width: 1px;
}

.table.table-inner {
  border: 0;
}

.table > thead > tr > th:first-child,
.table > tbody > tr > th:first-child,
.table > tfoot > tr > th:first-child,
.table > thead > tr > td:first-child,
.table > tbody > tr > td:first-child,
.table > tfoot > tr > td:first-child {
  padding-left: 30px;
}

.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > tbody > tr.succes > td,
.table > tfoot > tr.succes > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td {
  border: 0;
}

.table-striped td,
.table-striped th {
  border: 0 !important;
}

.table-bordered {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th {
  border-bottom: 0;
  border-left: 0;
}

.table-bordered > tbody > tr > td:last-child,
.table-bordered > tbody > tr > th:last-child {
  border-right: 0;
}

.table-bordered > thead > tr > th {
  border-left: 0;
}

.table-bordered > thead > tr > th:last-child {
  border-right: 0;
}

.table-vmiddle td {
  vertical-align: middle !important;
}

.table-responsive {
  overflow-x: initial;
  border: 0;
}

#todo {
  background: #FFC107;
  color: #fff;
  font-family: "satisfy", cursive;
}

#todo .card-header {
  padding: 28px 35px 20px;
  background-color: rgba(0, 0, 0, 0.03);
}

#todo .card-header h2 {
  font-size: 25px;
}

#todo .card-header h2 small {
  font-size: 18px;
  margin-top: 0;
}

#todo .card-body {
  font-size: 20px;
  position: relative;
}

#todo .list-group-item {
  padding: 0;
}

#todo .checkbox .input-helper:before,
#todo .checkbox .input-helper:after {
  top: 5px;
}

#todo .checkbox input:checked + i + span {
  text-decoration: line-through;
}

.t-add,
.ta-block,
.ta-btn {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.t-add {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  top: -25px;
  right: 23px;
  max-height: 300px;
}

.t-add .ta-block {
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}

.t-add .ta-block textarea {
  padding: 25px 25px 45px;
  resize: none;
  width: 100%;
  font-size: 24px;
  color: #FFC107;
  position: absolute;
  height: 100%;
  border: 0;
  outline: none;
}

.t-add:not(.toggled) {
  overflow: hidden;
}

.t-add:not(.toggled) .ta-btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 23px;
  color: #FF9800;
  line-height: 51px;
}

.t-add.toggled {
  width: calc(100% - 47px);
  height: calc(100% - 25px);
  border-radius: 2px;
  top: 0;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  overflow: visible;
}

.t-add.toggled .ta-btn {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: none;
}

.t-add.toggled .ta-block {
  opacity: 1;
  filter: alpha(opacity=100);
}

.t-add.toggled .ta-block .tab-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px 10px;
  border-top: 1px solid #EEE;
  z-index: 1;
}

.t-add.toggled .ta-block .tab-actions > a {
  font-size: 25px;
  padding: 0 6px;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 48px;
  display: inline-block;
}

.t-add.toggled .ta-block .tab-actions > a:hover {
  background-color: #eee;
}

.btn {
  border: 0;
  text-transform: uppercase;
}

.btn[class*=bgm-]:not(.bgm-white) {
  color: #fff;
}

.btn .caret {
  margin-top: -3px;
}

.btn-group:not(.bootstrap-select),
.btn-group-vertical:not(.bootstrap-select) {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.btn-group .btn,
.btn-group-vertical .btn,
.btn-group .btn:active,
.btn-group-vertical .btn:active,
.btn-group .btn:focus,
.btn-group-vertical .btn:focus,
.btn-group .btn-group,
.btn-group-vertical .btn-group {
  box-shadow: none !important;
}

.btn-group .btn,
.btn-group-vertical .btn {
  margin: 0;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 2px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.btn-link {
  color: #797979;
  text-decoration: none;
  border-radius: 2px;
}

.btn-link:hover {
  color: #0a0a0a;
}

.btn-inverse {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}

.btn-inverse:focus,
.btn-inverse.focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}

.btn-inverse:hover {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0);
}

.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #fff;
  background-color: #1a1a1a;
  border-color: rgba(0, 0, 0, 0);
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}

.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus {
  background-color: #454545;
  border-color: transparent;
}

.btn-inverse .badge {
  color: #454545;
  background-color: #fff;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus,
.btn-inverse:active,
.open > .dropdown-toggle.btn-inverse {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}

.btn-inverse:hover:hover,
.btn-inverse:focus:hover,
.btn-inverse.focus:hover,
.btn-inverse:active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:hover:focus,
.btn-inverse:focus:focus,
.btn-inverse.focus:focus,
.btn-inverse:active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:hover.focus,
.btn-inverse:focus.focus,
.btn-inverse.focus.focus,
.btn-inverse:active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #fff;
  background-color: #454545;
  border-color: transparent;
}

.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}

.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active {
  background-color: #454545;
  border-color: transparent;
}

.btn-inverse .badge {
  color: #454545;
  background-color: #fff;
}

.btn-icon {
  border-radius: 50%;
  width: 40px;
  line-height: 42px;
  height: 40px;
  padding: 0;
  text-align: center;
}

.btn-icon .zmdi {
  font-size: 17px;
}

.btn-icon-text > .zmdi {
  font-size: 15px;
  vertical-align: top;
  display: inline-block;
  margin-top: 2px;
  line-height: 100%;
  margin-right: 5px;
}

.btn-float {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 46px !important;
}

.btn-float:not(.m-btn) {
  position: absolute !important;
}

.btn-float i {
  font-size: 23px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.btn-float:hover i {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.btn-float:not(.bgm-white):not(.bgm-gray) > i {
  color: #fff;
}

.btn-float.bgm-white > i,
.btn-float.bgm-gray > i {
  color: #333;
}

.open .btn {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.open .btn:focus,
.open .btn:active {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

/*----------------------------------------
    Material Design Add button
------------------------------------------*/
.m-btn {
  z-index: 1;
  bottom: 40px;
  right: 40px;
  position: fixed !important;
}

label {
  font-weight: 500;
}

/*-----------------------------------
    Reset Focus and Active shadows
------------------------------------*/
input:active,
input:focus {
  outline: 0;
  box-shadow: none !important;
}

.form-control {
  box-shadow: none !important;
  resize: none;
  border-radius: 0;
}

.form-control:active,
.form-control:focus {
  box-shadow: none;
}

.form-control:not(.fc-alt) {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
}

.form-control:not(.fc-alt).auto-size {
  padding-top: 6px;
}

.form-group {
  margin-bottom: 25px;
}

/*------------------------
    Checkbox and Radio
-------------------------*/
.input-helper:before,
.input-helper:after,
.checkbox label:before,
.radio label:before,
.radio-inline:before,
.checkbox-inline:before {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.checkbox,
.radio {
  padding-top: 0 !important;
}

.checkbox label,
.radio label {
  display: block;
  padding-left: 30px;
}

.checkbox input,
.radio input {
  top: 0;
  left: 0;
  margin-left: 0 !important;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: 0;
}

.checkbox input:checked + .input-helper:before,
.radio input:checked + .input-helper:before {
  border-color: #607D8B;
}

.checkbox .input-helper:before,
.radio .input-helper:before,
.checkbox .input-helper:after,
.radio .input-helper:after {
  position: absolute;
  content: "";
}

.checkbox .input-helper:before,
.radio .input-helper:before {
  left: 0;
  border: 2px solid #7a7a7a;
}

.checkbox.disabled,
.radio.disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.checkbox input {
  width: 17px;
  height: 17px;
}

.checkbox input:checked + .input-helper:before {
  background-color: #607D8B;
}

.checkbox input:checked + .input-helper:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.checkbox .input-helper:before {
  top: 0;
  width: 17px;
  height: 17px;
  border-radius: 2px;
}

.checkbox .input-helper:after {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  font-size: 12px;
  left: 2px;
  top: 0;
  color: #fff;
  font-weight: bold;
}

.radio input {
  width: 19px;
  height: 19px;
}

.radio input:checked + .input-helper:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.radio .input-helper:before {
  top: -1px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
}

.radio .input-helper:after {
  width: 9px;
  height: 9px;
  background: #009688;
  border-radius: 50%;
  top: 4px;
  left: 5px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.checkbox-inline,
.radio-inline {
  vertical-align: top;
  margin-top: 0;
  padding-left: 25px;
}

.checkbox-light .input-helper:before,
.radio-light .input-helper:before {
  border-color: #fff;
}

.checkbox-light input:checked + .input-helper:before {
  border-color: #fff;
  background-color: #fff;
}

.checkbox-light input:checked + .input-helper:after {
  color: #333;
}

/*-------------------------
    Select
--------------------------*/
html:not(.ie9) .select {
  position: relative;
}

html:not(.ie9) .select:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  html:not(.ie9) .select:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
html:not(.ie9) .select:not(.fg-line):before {
  right: 0;
}

html:not(.ie9) .select.fg-line:before {
  right: 10px;
}

/*-------------------------
    Input Group Addon
--------------------------*/
.input-group:not(.input-group-lg):not(.input-group-sm) .input-group-addon {
  font-size: 15px;
}

.input-group-addon {
  border-width: 0px 0px 1px 0px;
  min-width: 42px;
}

.input-group-addon > .zmdi {
  position: relative;
  top: 3px;
}

/*-------------------------
    Input Feilds
--------------------------*/
.fg-line {
  position: relative;
  vertical-align: top;
}

.fg-line:not(.form-group) {
  display: inline-block;
  width: 100%;
}

.fg-line .form-control:disabled {
  color: #9d9d9d;
  background: transparent;
}

.fg-line:not(.disabled):after,
.fg-line:not(.readonly):after {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.fg-line:not([class*=has-]):after {
  background: #2196F3;
}

.fg-line.readonly .form-control {
  color: #9d9d9d;
  background: transparent;
}

.fg-line.fg-toggled:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.fg-float {
  margin-top: 2px;
  position: relative;
}

.fg-float .form-control {
  position: relative;
  background: transparent;
  z-index: 1;
}

.fg-float .form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.fg-float .form-control:-ms-input-placeholder {
  color: #fff;
}

.fg-float .form-control::-webkit-input-placeholder {
  color: #fff;
}

.fg-float .fg-label {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  position: absolute;
  top: 5px;
  font-weight: 400;
  color: #959595;
  pointer-events: none;
  z-index: 0;
  left: 0;
  white-space: nowrap;
}

.fg-float .fg-toggled .fg-label {
  top: -20px;
  font-size: 11px;
}

.control-label {
  font-weight: normal;
}

/*-------------------------
    Toggle Switch
--------------------------*/
.toggle-switch {
  display: inline-block;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-switch .ts-label {
  display: inline-block;
  margin: 0 20px 0 0;
  vertical-align: top;
  -webkit-transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
  transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
}

.toggle-switch .ts-helper {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 16px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.26);
  -webkit-transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}

.toggle-switch .ts-helper:before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  width: 24px;
  height: 24px;
  background: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  webkit-transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.toggle-switch:not(.disabled) .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

.toggle-switch input {
  position: absolute;
  z-index: 1;
  width: 46px;
  margin: 0 0 0 -4px;
  height: 24px;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
}

.toggle-switch input:checked + .ts-helper:before {
  left: 20px;
}

.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper {
  background: rgba(0, 150, 136, 0.5);
}

.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper:before {
  background: #009688;
}

.toggle-switch:not([data-ts-color]) input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.toggle-switch.disabled {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.toggle-switch[data-ts-color=red] input:not(:disabled):checked + .ts-helper {
  background: rgba(241, 69, 61, 0.5);
}

.toggle-switch[data-ts-color=red] input:not(:disabled):checked + .ts-helper:before {
  background: #f1453d;
}

.toggle-switch[data-ts-color=red] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(241, 69, 61, 0.2);
}

.toggle-switch[data-ts-color=blue] input:not(:disabled):checked + .ts-helper {
  background: rgba(33, 150, 243, 0.5);
}

.toggle-switch[data-ts-color=blue] input:not(:disabled):checked + .ts-helper:before {
  background: #2196F3;
}

.toggle-switch[data-ts-color=blue] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(33, 150, 243, 0.2);
}

.toggle-switch[data-ts-color=amber] input:not(:disabled):checked + .ts-helper {
  background: rgba(255, 193, 7, 0.5);
}

.toggle-switch[data-ts-color=amber] input:not(:disabled):checked + .ts-helper:before {
  background: #FFC107;
}

.toggle-switch[data-ts-color=amber] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(255, 193, 7, 0.2);
}

.toggle-switch[data-ts-color=purple] input:not(:disabled):checked + .ts-helper {
  background: rgba(186, 104, 200, 0.5);
}

.toggle-switch[data-ts-color=purple] input:not(:disabled):checked + .ts-helper:before {
  background: #BA68C8;
}

.toggle-switch[data-ts-color=purple] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(186, 104, 200, 0.2);
}

.toggle-switch[data-ts-color=pink] input:not(:disabled):checked + .ts-helper {
  background: rgba(233, 30, 99, 0.5);
}

.toggle-switch[data-ts-color=pink] input:not(:disabled):checked + .ts-helper:before {
  background: #E91E63;
}

.toggle-switch[data-ts-color=pink] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(233, 30, 99, 0.2);
}

.toggle-switch[data-ts-color=lime] input:not(:disabled):checked + .ts-helper {
  background: rgba(205, 220, 57, 0.5);
}

.toggle-switch[data-ts-color=lime] input:not(:disabled):checked + .ts-helper:before {
  background: #CDDC39;
}

.toggle-switch[data-ts-color=lime] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(205, 220, 57, 0.2);
}

.toggle-switch[data-ts-color=cyan] input:not(:disabled):checked + .ts-helper {
  background: rgba(0, 188, 212, 0.5);
}

.toggle-switch[data-ts-color=cyan] input:not(:disabled):checked + .ts-helper:before {
  background: #00BCD4;
}

.toggle-switch[data-ts-color=cyan] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 188, 212, 0.2);
}

.toggle-switch[data-ts-color=green] input:not(:disabled):checked + .ts-helper {
  background: rgba(76, 175, 80, 0.5);
}

.toggle-switch[data-ts-color=green] input:not(:disabled):checked + .ts-helper:before {
  background: #4CAF50;
}

.toggle-switch[data-ts-color=green] input:not(:disabled):checked + .ts-helper:active:before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(76, 175, 80, 0.2);
}

/*-------------------------
    Validation
--------------------------*/
.has-error .checkbox .input-helper:before {
  border-color: #f8a09c;
}

.has-error .checkbox .input-helper:after {
  border-bottom-color: #f4736d;
  border-left-color: #f4736d;
}

.has-error .fg-line:after {
  background: #f1453d;
}

.has-success .checkbox .input-helper:before {
  border-color: #92cf94;
}

.has-success .checkbox .input-helper:after {
  border-bottom-color: #6ec071;
  border-left-color: #6ec071;
}

.has-success .fg-line:after {
  background: #4CAF50;
}

.has-warning .checkbox .input-helper:before {
  border-color: #ffc166;
}

.has-warning .checkbox .input-helper:after {
  border-bottom-color: #ffad33;
  border-left-color: #ffad33;
}

.has-warning .fg-line:after {
  background: #FF9800;
}

/*-------------------------
    IE 9 Placeholder
--------------------------*/
.ie9-placeholder {
  color: #888 !important;
  font-weight: normal;
}

/*-----------------------------------
    Fix Textarea Scrollbar in IE9
------------------------------------*/
.ie9 textarea {
  overflow: auto !important;
}

.pagination {
  border-radius: 0;
}

.pagination > li {
  margin: 0 2px;
  display: inline-block;
  vertical-align: top;
}

.pagination > li > a,
.pagination > li > span {
  border-radius: 50% !important;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  position: relative;
  cursor: pointer;
  background-clip: padding-box;
}

.pagination > li > a > .zmdi,
.pagination > li > span > .zmdi {
  font-size: 22px;
  line-height: 39px;
}

.pagination > li.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/*-------------------------
    Listview Pagination
--------------------------*/
.lg-pagination {
  width: 100%;
  text-align: center;
  padding: 40px 0;
  margin-top: 0;
}

/*-------------------------
    Pager
--------------------------*/
.pager li > a,
.pager li > span {
  padding: 5px 10px 6px;
  color: #7E7E7E;
}

.popover {
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
}

.popover-title {
  border-bottom: 0;
  padding: 15px;
  font-size: 12px;
  text-transform: uppercase;
}

.popover-title + .popover-content {
  padding-top: 0;
}

.popover-content {
  padding: 15px;
}

.popover-content p {
  margin-bottom: 0;
}

.fw-container .tab-content {
  padding: 25px 0;
}

.fw-container .fw-footer {
  text-align: center;
  margin: 30px 0 0;
  width: 100%;
  border-top: 2px solid #eee;
  padding: 15px 0;
}

.alert {
  padding-left: 30px;
  font-size: 13px;
}

.alert span {
  cursor: pointer;
}

.alert:not(.alert-dismissible) {
  padding-right: 30px;
}

.alert.alert-dismissable {
  padding-right: 44px;
}

.alert-inverse {
  background-color: #333;
  border-color: transparent;
  color: #fff;
}

.alert-inverse hr {
  border-top-color: rgba(0, 0, 0, 0);
}

.alert-inverse .alert-link {
  color: #e6e6e6;
}

.growl-animated.alert-inverse {
  box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
}

.growl-animated.alert-info {
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
}

.growl-animated.alert-success {
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.growl-animated.alert-warning {
  box-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
}

.growl-animated.alert-danger {
  box-shadow: 0 0 5px rgba(241, 69, 61, 0.5);
}

.alert-link {
  color: #fff !important;
}

/*-------------------------
	Lightbox
-------------------------*/
.lightbox .lightbox-item {
  overflow: hidden;
}

.lightbox .lightbox-item > img {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  width: 100%;
  border-radius: 2px;
}

.lightbox .lightbox-item:hover {
  cursor: pointer;
}

.lightbox .lightbox-item:hover img {
  -webkit-transform: scale(1.35) rotate(10deg);
  -ms-transform: scale(1.35) rotate(10deg);
  -o-transform: scale(1.35) rotate(10deg);
  transform: scale(1.35) rotate(10deg);
}

.lightbox [data-src]:before,
.lightbox [data-src]:after {
  content: " ";
  display: table;
}

.lightbox [data-src]:after {
  clear: both;
}

.lightbox [data-src]:before,
.lightbox [data-src]:after {
  content: " ";
  display: table;
}

.lightbox [data-src]:after {
  clear: both;
}

.lightbox .lightbox-item:not(.p-item) {
  position: relative;
}

/*-------------------------
	Carousel
--------------------------*/
.carousel .carousel-control {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
}

.carousel .carousel-control .zmdi {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 100%;
}

@media screen and (min-width: 768px) {
  .carousel .carousel-control .zmdi {
    font-size: 60px;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
  }
}
@media screen and (max-width: 991px) {
  .carousel .carousel-control .zmdi {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
  }
}
.carousel:hover .carousel-control {
  opacity: 1;
  filter: alpha(opacity=100);
}

.carousel .carousel-caption {
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 50px;
}

.carousel .carousel-caption > h3 {
  color: #fff;
  margin: 0 0 5px;
  font-weight: 300;
}

.carousel .carousel-caption > p {
  margin: 0;
}

@media screen and (max-width: 991px) {
  .carousel .carousel-caption {
    display: none;
  }
}
.carousel .carousel-indicators {
  margin: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0 6px;
  background: rgba(0, 0, 0, 0.6);
}

.carousel .carousel-indicators li {
  border-radius: 0;
  width: 15px;
  border: 0;
  background: #fff;
  height: 3px;
  margin: 0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.carousel .carousel-indicators li.active {
  width: 25px;
  height: 3px;
  background: #FF9800;
}

@media (min-width: 768px) {
  .modal {
    text-align: center;
  }

  .modal:before {
    content: "";
    height: 100%;
    width: 1px;
    display: inline-block;
    vertical-align: middle;
  }

  .modal .modal-dialog {
    text-align: left;
    margin: 10px auto;
    display: inline-block;
    vertical-align: middle;
  }
}
.modal .modal-content {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  border: 0;
}

.modal .modal-header {
  padding: 23px 26px;
}

.modal .modal-title {
  font-weight: 400;
  font-size: 15px;
}

.modal .modal-title small {
  display: block;
  margin-top: 8px;
}

.modal .modal-body {
  padding: 0 26px 10px;
}

.modal .modal-footer .btn-link {
  font-size: 14px;
  color: #2e353b;
}

.modal .modal-footer .btn-link:hover {
  background-color: #eee;
}

.modal:not([data-modal-color]) .modal-footer .btn-link {
  font-weight: 500;
}

.modal:not([data-modal-color]) .modal-footer .btn-link:hover {
  background-color: #eee;
}

.modal[data-modal-color] {
  color: #fff;
}

.modal[data-modal-color] .modal-title,
.modal[data-modal-color] .modal-footer .btn-link {
  color: #fff;
}

.modal[data-modal-color] .modal-footer {
  background: rgba(0, 0, 0, 0.1);
}

.modal[data-modal-color] .modal-backdrop {
  background: #fff;
}

.modal[data-modal-color] .modal-footer .btn-link {
  font-weight: 400;
}

.modal[data-modal-color] .modal-footer .btn-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.modal[data-modal-color=blue] .modal-content {
  background: #2196F3;
}

.modal[data-modal-color=cyan] .modal-content {
  background: #00BCD4;
}

.modal[data-modal-color=green] .modal-content {
  background: #4CAF50;
}

.modal[data-modal-color=lightgreen] .modal-content {
  background: #8BC34A;
}

.modal[data-modal-color=lightblue] .modal-content {
  background: #03A9F4;
}

.modal[data-modal-color=amber] .modal-content {
  background: #FFC107;
}

.modal[data-modal-color=teal] .modal-content {
  background: #009688;
}

.modal[data-modal-color=orange] .modal-content {
  background: #FF9800;
}

.modal[data-modal-color=bluegray] .modal-content {
  background: #607D8B;
}

.modal[data-modal-color=red] .modal-content {
  background: #f1453d;
}

.panel {
  box-shadow: none;
  border: 0;
}

.panel-heading {
  padding: 0;
}

.panel-title > a {
  padding: 10px 15px;
  display: block;
  font-size: 13px;
}

.panel-collapse .panel-heading {
  position: relative;
}

.panel-collapse .panel-heading .panel-title > a {
  padding: 8px 5px 16px 30px;
  color: #000;
  position: relative;
}

.panel-collapse .panel-heading .panel-title > a:after,
.panel-collapse .panel-heading .panel-title > a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  content: "";
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.panel-collapse .panel-heading .panel-title > a:after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.panel-collapse .panel-heading:not(.active) .panel-title > a:before {
  background: #eee;
}

.panel-collapse .panel-heading:before,
.panel-collapse .panel-heading:after {
  font-family: "Material-Design-Iconic-Font";
  font-size: 17px;
  position: absolute;
  left: 0;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 4px;
}

.panel-collapse .panel-heading:before {
  content: "";
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.panel-collapse .panel-heading:after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  content: "";
}

.panel-collapse .panel-heading.active .panel-title > a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.panel-collapse .panel-heading.active:before {
  -webkit-transform: scale(0) rotate(-90deg);
  -ms-transform: scale(0) rotate(-90deg);
  -o-transform: scale(0) rotate(-90deg);
  transform: scale(0) rotate(-90deg);
}

.panel-collapse .panel-heading.active:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.panel-collapse .panel-body {
  border-top: 0 !important;
  padding-left: 5px;
  padding-right: 5px;
}

.panel-group:not([data-collapse-color]) .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #2196F3;
}

.panel-group[data-collapse-color=red] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #f1453d;
}

.panel-group[data-collapse-color=green] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #4CAF50;
}

.panel-group[data-collapse-color=amber] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #FFC107;
}

.panel-group[data-collapse-color=teal] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #009688;
}

.panel-group[data-collapse-color=black] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #000000;
}

.panel-group[data-collapse-color=cyan] .panel-collapse .panel-heading.active .panel-title > a:after {
  background: #00BCD4;
}

.tooltip-inner {
  border-radius: 2px;
  padding: 5px 15px 5px;
}

.breadcrumb {
  border-bottom: 1px solid #E5E5E5;
  border-radius: 0;
  margin-top: -27px;
  margin-bottom: 50px;
}

@media (min-width: 1280px) {
  .breadcrumb {
    padding: 10px 33px 11px 300px;
  }
}
@media (max-width: 1279px) {
  .breadcrumb {
    padding: 10px 33px 11px;
  }
}
.breadcrumb > li > a {
  color: #5E5E5E;
}

.breadcrumb > li > a:hover {
  color: #7c7c7c;
}

.messages {
  height: calc(100vh - 130px);
  position: relative;
  overflow: hidden;
}

.messages header {
  min-height: 56px;
}

.messages header .actions {
  position: absolute;
  top: 12px;
  right: 13px;
}

/*-----------------------------------
    Sidebar
------------------------------------*/
.m-sidebar {
  width: 300px;
  border-right: 1px solid #f3f3f3;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  /* Scrollbar fix */
}

@media (max-width: 767px) {
  .m-sidebar {
    width: 60px;
  }

  .m-sidebar .list-group-item {
    padding: 10px !important;
  }

  .m-sidebar .ms-time {
    display: none;
  }
}
.m-sidebar header {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px;
}

.m-sidebar header h2 {
  line-height: 100%;
  font-size: 15px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.m-sidebar .lgi-heading {
  max-width: 120px;
}

.m-sidebar .list-group {
  height: calc(100% - 130px);
}

.m-sidebar .mCSB_scrollTools {
  z-index: 2;
}

.ms-time {
  position: absolute;
  top: 16px;
  right: 25px;
  color: #777777;
}

.ms-search {
  padding: 20px 25px;
  position: relative;
}

.ms-search .zmdi-search {
  position: absolute;
  left: 0px;
  top: 8px;
  font-size: 20px;
}

.ms-search input {
  padding-left: 25px !important;
}

/*-----------------------------------
    Body
------------------------------------*/
.m-body {
  position: relative;
  height: 100%;
}

@media (min-width: 768px) {
  .m-body {
    padding-left: 300px;
  }
}
@media (max-width: 767px) {
  .m-body {
    padding-left: 60px;
  }
}
.mb-header {
  border-bottom: 1px solid #f3f3f3;
  box-shadow: 0px -1px 0px 1px #f3f3f3;
  position: relative;
  z-index: 2;
}

.mbh-user {
  padding: 12px 20px;
}

.mbh-user > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.mb-list {
  height: calc(100% - 55px);
  position: relative;
}

.mbl-messages {
  padding: 50px 50px 0;
  height: calc(100vh - 259px);
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .mbl-messages {
    padding: 20px 20px 0;
  }
}
.mblm-item {
  position: relative;
  margin-bottom: 10px;
}

.mblm-item > div {
  display: inline-block;
  max-width: 70%;
  padding: 15px 20px;
  border-radius: 2px;
  position: relative;
}

.mblm-item small {
  display: block;
  color: #777777;
  padding: 5px 20px;
}

.mblm-item-left > div {
  background-color: #eee;
}

.mblm-item-right {
  text-align: right;
}

.mblm-item-right > div {
  background-color: #FFC107;
  color: #fff;
}

.mblmi-img {
  margin: -10px -15px;
  cursor: pointer;
}

.mblmi-img img {
  max-width: 250px;
  border-radius: 2px;
}

.mbl-compose {
  border-top: 1px solid #eee;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 65px 15px 15px;
}

.mbl-compose textarea {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  border: 0;
  resize: none;
  background: #eee;
  padding: 5px 10px;
  color: #5E5E5E;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.mbl-compose button {
  position: absolute;
  top: 14px;
  right: 15px;
  background: transparent;
  border: 0;
  font-size: 25px;
}

.mbl-compose button:hover {
  color: #333;
}

.four-zero {
  height: 100vh;
  background-color: #607D8B;
}

.fz-block {
  background: #557382;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  margin-top: -150px;
  color: #ECF0F1;
  text-align: center;
  padding: 25px;
  height: 300px;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}

.fz-block h2 {
  font-size: 130px;
  line-height: 100%;
  color: #ECF0F1;
  font-weight: 100;
}

@media (max-width: 767px) {
  .fz-block {
    width: calc(100% - 40px);
    left: 20px;
    margin-left: 0;
    height: 260px;
    margin-top: -130px;
  }

  .fz-block h2 {
    font-size: 90px;
  }
}
.fz-block small {
  display: block;
  font-size: 26px;
  margin-top: -10px;
}

.fzb-links {
  margin-top: 20px;
}

.fzb-links > a {
  font-size: 16px;
  display: inline-block;
  color: #ECF0F1;
  margin: 0 1px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.09);
  border-radius: 50%;
  text-align: center;
}

.fzb-links > a:hover {
  background: rgba(0, 0, 0, 0.2);
}

.login-content {
  min-height: 100vh;
  text-align: center;
  background-color: #009688;
}

.login-content:before {
  display: inline-block;
  content: "";
  height: 100vh;
  width: 1px;
  vertical-align: middle;
}

.lc-block {
  max-width: 370px;
  padding: 20px 0;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.lc-block:not(.toggled) {
  display: none;
}

.lc-block.toggled {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 10;
}

.lc-block:not(.lc-block-alt) .lcb-form {
  padding: 35px 30px 35px 30px;
}

.lc-block:not(.lc-block-alt) .btn-login {
  top: 50%;
  margin-top: -43px;
  right: -25px;
}

.lc-block .checkbox {
  text-align: left;
}

.lcb-form {
  background: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

/*-----------------------------
    Login Navigation
------------------------------*/
.lcb-navigation {
  margin-top: 15px;
}

.lcb-navigation a,
.lcb-navigation a span {
  -webkit-transition: width;
  -o-transition: width;
  transition: width;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

.lcb-navigation a {
  color: #fff;
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}

.lcb-navigation a i {
  width: 30px;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 30px;
}

.lcb-navigation a span {
  width: 0;
  overflow: hidden;
  display: inline-block;
  line-height: 29px;
  margin-left: -3px;
}

.lcb-navigation a:hover span {
  width: 100%;
}

.lcb-navigation a[data-ma-block="#l-register"]:hover {
  width: 95px;
}

.lcb-navigation a[data-ma-block="#l-forget-password"]:hover {
  width: 147px;
}

.lcb-navigation a[data-ma-block="#l-login"]:hover {
  width: 85px;
}

/*-----------------------------
    Lockscreen
------------------------------*/
.lc-block-alt .lcb-form {
  padding: 70px 35px 60px;
}

.lc-block-alt .btn-login {
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}

.lcb-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  top: -35px;
  left: 50%;
  margin-left: -50px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.18);
}

#profile-main {
  min-height: 500px;
  position: relative;
}

@media (min-width: 1200px) {
  #profile-main .pm-overview {
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  #profile-main .pm-overview {
    width: 250px;
  }
}
@media (min-width: 768px) {
  #profile-main .pm-overview {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-right: 2px solid #eee;
  }
}
@media (max-width: 767px) {
  #profile-main .pm-overview {
    width: 100%;
    background: #333;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  #profile-main .pm-body {
    padding-left: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  #profile-main .pm-body {
    padding-left: 250px;
  }
}
@media (max-width: 767px) {
  #profile-main .pm-body {
    padding-left: 0;
  }
}
#profile-main .pmo-pic {
  position: relative;
  margin: 20px;
}

@media (min-width: 768px) {
  #profile-main .pmo-pic img {
    width: 100%;
    border-radius: 2px 2px 0 0;
  }
}
@media (max-width: 767px) {
  #profile-main .pmo-pic img {
    width: 180px;
    display: inline-block;
    height: 180px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  }
}
#profile-main .pmo-pic .pmo-stat {
  border-radius: 0 0 2px 2px;
  color: #fff;
  text-align: center;
  padding: 30px 5px 0;
}

@media (min-width: 768px) {
  #profile-main .pmo-pic .pmo-stat {
    background: #FFC107;
    padding-bottom: 15px;
  }
}
#profile-main .pmo-pic .pmop-edit {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.38);
  text-align: center;
  padding: 10px 10px 11px;
}

#profile-main .pmo-pic .pmop-edit:hover {
  background: rgba(0, 0, 0, 0.8);
}

#profile-main .pmo-pic .pmop-edit i {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -3px;
}

@media (min-width: 768px) {
  #profile-main .pmo-pic .pmop-edit {
    width: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  #profile-main .pmo-pic .pmop-edit i {
    margin-right: 4px;
  }
}
#profile-main .pmo-pic:hover .pmop-edit {
  opacity: 1;
  filter: alpha(opacity=100);
}

#profile-main .pmo-pic .pmop-message {
  position: absolute;
  bottom: 27px;
  left: 50%;
  margin-left: -25px;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu {
  padding: 5px 0 55px;
  left: -90px;
  width: 228px;
  height: 150px;
  top: -74px;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu textarea {
  width: 100%;
  height: 95px;
  border: 0;
  resize: none;
  padding: 10px 19px;
}

#profile-main .pmo-pic .pmop-message .dropdown-menu button {
  bottom: 5px;
  left: 88px;
}

#profile-main .pmb-block {
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  #profile-main .pmb-block {
    padding: 40px 42px 0;
  }
}
@media (max-width: 1199px) {
  #profile-main .pmb-block {
    padding: 30px 20px 0;
  }
}
#profile-main .pmb-block:last-child {
  margin-bottom: 50px;
}

#profile-main .pmb-block .pmbb-header {
  margin-bottom: 25px;
  position: relative;
}

#profile-main .pmb-block .pmbb-header .actions {
  position: absolute;
  top: -2px;
  right: 0;
}

#profile-main .pmb-block .pmbb-header h2 {
  margin: 0;
  font-weight: 100;
  font-size: 20px;
}

#profile-main .pmb-block .pmbb-edit {
  position: relative;
  z-index: 1;
  display: none;
}

#profile-main .pmb-block .pmbb-edit,
#profile-main .pmb-block .pmbb-view {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#profile-main .pmb-block.toggled .pmbb-edit {
  display: block;
}

#profile-main .pmb-block.toggled .pmbb-view {
  display: none;
}

#profile-main .pmo-block {
  padding: 25px;
}

#profile-main .pmo-block > h2 {
  font-size: 16px;
  margin: 0 0 15px;
}

#profile-main .pmo-items .pmob-body {
  padding: 0 10px;
}

#profile-main .pmo-items a {
  display: block;
  padding: 4px;
}

#profile-main .pmo-items a img {
  width: 100%;
}

.pmo-contact ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pmo-contact ul li {
  position: relative;
  padding: 8px 0 8px 35px;
}

.pmo-contact ul li i {
  font-size: 18px;
  vertical-align: top;
  line-height: 100%;
  position: absolute;
  left: 0;
  width: 18px;
  text-align: center;
}

.pmo-map {
  margin: 20px -21px -18px;
  display: block;
}

.pmo-map img {
  width: 100%;
}

@media (max-width: 767px) {
  .c-timeline {
    background: #f3f3f3;
    box-shadow: none;
  }

  .c-timeline .tab-nav {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }
}
.timeline {
  position: relative;
}

@media (min-width: 768px) {
  .timeline {
    padding: 50px;
    padding-left: 100px;
  }
}
@media (max-width: 767px) {
  .timeline {
    margin-top: 30px;
  }
}
.t-view {
  border: 1px solid #eee;
  position: relative;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .t-view {
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  }
}
.t-view .tv-header {
  padding: 16px 18px;
  border-bottom: 1px solid #eee;
  background: #F9F9F9;
}

.t-view .tv-header .actions {
  position: absolute;
  top: 5px;
  right: 10px;
}

.t-view .tv-body {
  padding: 23px 25px;
}

.t-view .tv-body .tvb-lightbox {
  margin: 0 -8px 15px;
}

.t-view .tv-body .tvb-lightbox [data-src] {
  padding: 0 5px;
  margin-bottom: 5px;
}

.t-view .tvh-user {
  display: block;
}

.t-view .tvh-user img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.t-view:before {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -70px;
  top: 0;
  border: 3px solid #FFF;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  color: #FFF;
  font-family: "Material-Design-Iconic-Font";
  z-index: 1;
}

.t-view:after {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 1px;
  height: calc(100% + 37px);
}

.t-view[data-tv-type=text]:before {
  content: "";
  background: #00BCD4;
  box-shadow: 0 0 0 1px #00BCD4;
}

.t-view[data-tv-type=text]:after {
  background: #00BCD4;
}

.t-view[data-tv-type=image]:before {
  content: "";
  background: #4CAF50;
  box-shadow: 0 0 0 1px #4CAF50;
}

.t-view[data-tv-type=image]:after {
  background: #4CAF50;
}

.t-view[data-tv-type=video]:before {
  content: "";
  background: #FFC107;
  box-shadow: 0 0 0 1px #FFC107;
}

.t-view[data-tv-type=video]:after {
  background: #FFC107;
}

.t-view .tvb-stats {
  list-style: none;
  padding: 0;
  margin: 10px 0 20px;
}

.t-view .tvb-stats > li {
  display: inline-block;
  padding: 5px 10px 6px;
  border: 1px solid #ccc;
  margin-right: 2px;
}

.t-view .tvb-stats > li i {
  font-size: 15px;
  line-height: 100%;
  vertical-align: top;
  margin-top: 2px;
}

.t-view .tvb-stats > li.tvbs-comments {
  border-color: #4CAF50;
  color: #4CAF50;
}

.t-view .tvb-stats > li.tvbs-likes {
  border-color: #03A9F4;
  color: #03A9F4;
}

.t-view .tvb-stats > li.tvbs-views {
  border-color: #FF9800;
  color: #FF9800;
}

.tv-comments .tvc-lists {
  padding: 0;
  list-style: none;
  margin: 0;
}

.tv-comments .tvc-lists > li {
  padding: 15px 20px;
  margin: 0;
  border-top: 1px solid #eee;
}

.tvc-more {
  color: #333;
  display: block;
  margin-bottom: -10px;
}

.tvc-more:hover {
  color: #000;
}

.tvc-more i {
  vertical-align: middle;
  margin-right: 5px;
}

.p-header {
  position: relative;
  margin: 0 -7px;
}

.p-header .actions {
  position: absolute;
  top: -18px;
  right: 0;
}

.p-menu {
  list-style: none;
  padding: 0 5px;
  margin: 0 0 30px;
}

.p-menu > li {
  display: inline-block;
  vertical-align: top;
}

.p-menu > li > a {
  display: block;
  padding: 5px 20px 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
}

.p-menu > li > a > i {
  margin-right: 4px;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
}

.p-menu > li:not(.active) > a {
  color: #4285F4;
}

.p-menu > li:not(.active) > a:hover {
  color: #333;
}

.p-menu > li.active > a {
  color: #000;
}

@media (max-width: 991px) {
  .p-menu .pm-search {
    margin: 20px 2px 30px;
    display: block;
  }

  .p-menu .pm-search input[type=text] {
    width: 100%;
    border: 1px solid #ccc;
  }
}
.p-menu .pms-inner {
  margin: -2px 0 0;
  position: relative;
  top: -2px;
  overflow: hidden;
  white-space: nowrap;
}

.p-menu .pms-inner i {
  vertical-align: top;
  font-size: 20px;
  line-height: 100%;
  position: absolute;
  left: 9px;
  top: 8px;
  color: #333;
}

.p-menu .pms-inner input[type=text] {
  height: 35px;
  border-radius: 2px;
  padding: 0 10px 0 40px;
}

@media (min-width: 768px) {
  .p-menu .pms-inner input[type=text] {
    border: 1px solid #fff;
    width: 50px;
    background: transparent;
    position: relative;
    z-index: 1;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }

  .p-menu .pms-inner input[type=text]:focus {
    border-color: #DFDFDF;
    width: 200px;
  }
}
.photos {
  margin: 2px 0 0;
}

.photos .lightbox {
  margin: 0 -8px;
}

.photos:not(.p-timeline) [data-src] {
  padding: 3px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
}

.p-timeline {
  position: relative;
  padding-left: 80px;
  margin-bottom: 75px;
}

.p-timeline [data-src] {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0 3px 3px 0;
}

.p-timeline:last-child .pt-line:before {
  height: 100%;
}

.ptb-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 20px;
}

.pt-line {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 14px;
}

.pt-line:before,
.pt-line:after {
  content: "";
  position: absolute;
}

.pt-line:before {
  width: 1px;
  height: calc(100% + 63px);
  background: #E2E2E2;
  top: 14px;
  right: -20px;
}

.pt-line:after {
  top: 2px;
  right: -26px;
  width: 13px;
  height: 13px;
  border: 1px solid #C1C1C1;
  border-radius: 50%;
}

.contacts:not(.c-profile) {
  padding: 0 8px;
}

.contacts > [class*=col-] {
  padding: 0 10px;
}

.contacts .c-item {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  margin-bottom: 24px;
}

.contacts .c-item .ci-avatar {
  display: block;
}

.contacts .c-item .ci-avatar img {
  width: 100%;
  border-radius: 2px 2px 0 0;
}

.contacts .ci-avatar {
  margin: -1px -1px 0;
}

.contacts .c-info {
  text-align: center;
  margin-top: 15px;
  padding: 0 5px;
}

.contacts .c-info strong {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.contacts .c-info small {
  color: #999;
  margin-top: 3px;
}

.contacts .c-info strong,
.contacts .c-info small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.contacts .c-footer {
  border-top: 1px solid #e2e2e2;
  margin-top: 18px;
}

.contacts .c-footer > button {
  padding: 7px 10px;
  color: #333;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  border-radius: 2px;
  background: #fff;
  border: 0;
}

.contacts .c-footer > button > i {
  font-size: 16px;
  vertical-align: middle;
  margin-top: -3px;
}

/*--------------------------------------------------
    Block Header
    Used for Heading outside the Cards.
---------------------------------------------------*/
.block-header {
  margin-bottom: 25px;
  position: relative;
}

.block-header > h2 {
  font-size: 19px;
  color: #333;
  margin: 0;
  font-weight: 400;
  float: left;
}

.block-header > h2 > small {
  display: block;
  text-transform: none;
  margin-top: 8px;
  margin-bottom: 20px;
  color: #9E9E9E;
  line-height: 140%;
}

.block-header .actions {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 4;
}

/*-------------------------
    Actions
--------------------------*/
.actions {
  list-style: none;
  padding: 0;
  z-index: 3;
  margin: 0;
}

.actions > li {
  display: inline-block;
  vertical-align: baseline;
}

.actions > li > a,
.actions > a,
span.no-link {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding-top: 5px;
}

.actions > li > a > i,
.actions > a > i {
  color: #adadad;
  font-size: 20px;
}

.actions > li > a:hover > i,
.actions > a:hover > i {
  color: #000;
}

.actions > li.open > a > i,
.actions.open > a > i {
  color: #000;
}

.actions > li.open > a:before,
.actions.open > a:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.actions.actions-alt > li > a > i {
  color: #fff;
}

.actions.actions-alt > li > a > i:hover {
  color: #fff;
}

.actions.actions-alt > li.open > a > i {
  color: #fff;
}

.actions.open {
  z-index: 4;
}

/*-------------------------
    Collapse Menu Icons
--------------------------*/
.line-wrap {
  width: 18px;
  height: 12px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  margin: 12px 20px;
}

.line-wrap .line {
  width: 18px;
  height: 2px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  background-color: #fff;
}

.line-wrap .line.center {
  margin: 3px 0;
}

.toggled .line-wrap {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.toggled .line-wrap .line.top {
  width: 12px;
  transform: translateX(8px) translateY(1px) rotate(45deg);
  -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
}

.toggled .line-wrap .line.bottom {
  width: 12px;
  transform: translateX(8px) translateY(-1px) rotate(-45deg);
  -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
}

/*-------------------------
    Load More
--------------------------*/
.load-more {
  text-align: center;
  margin-top: 30px;
}

.load-more a {
  padding: 8px 10px 6px;
  display: inline-block;
  background-color: #f1453d;
  color: #FFF;
  border-radius: 2px;
  white-space: nowrap;
}

.load-more a i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  margin-top: -2px;
}

.load-more a:hover {
  background-color: #ea1b11;
}

/*-------------------------
    View More
--------------------------*/
.view-more {
  display: block;
  text-align: center;
  padding: 10px 10px 15px;
  line-height: 100%;
  font-size: 11px;
  margin-top: 10px;
  text-transform: uppercase;
  color: #777777;
}

.view-more:hover {
  color: #5E5E5E;
}

/*-------------------------
    Page Loader
--------------------------*/
html:not(.ismobile) .page-loader {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

html:not(.ismobile) .page-loader .preloader {
  width: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -55px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 3000ms;
  animation-duration: 3000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

html:not(.ismobile) .page-loader .preloader p {
  white-space: nowrap;
  position: relative;
  left: -9px;
  top: 22px;
  color: #CCC;
}

html.ismobile .page-loader {
  display: none;
}

/*----------------------------------------
    Action Header
-----------------------------------------*/
.action-header {
  padding: 25px 30px;
  line-height: 100%;
  position: relative;
  z-index: 1;
  min-height: 65px;
  border-bottom: 1px solid #F7F7F7;
}

.action-header .actions {
  position: absolute;
  top: 18px;
  right: 17px;
  z-index: 10;
}

.ah-label {
  font-size: 15px;
}

.ah-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: #454E53;
  display: none;
}

.ahs-input {
  border: 0;
  padding: 0 26px;
  height: 100%;
  font-size: 15px;
  width: 100%;
  line-height: 60px;
  background-color: #F7F7F7;
}

.ahs-close {
  font-style: normal;
  position: absolute;
  top: 23px;
  right: 25px;
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  background: #E2E2E2;
  width: 18px;
  height: 18px;
  color: #6B6B6B;
  border-radius: 50%;
}

.ahs-close:hover {
  opacity: 0.8;
}

/*----------------------------------
    Backdrop
-----------------------------------*/
.ma-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
}

/*----------------------------------
    Avatar
-----------------------------------*/
[class*=avatar-img] {
  border-radius: 50%;
}

.avatar-img {
  width: 42px;
}

/*----------------------------------
    Stats Arrows
-----------------------------------*/
.stats-arrow {
  width: 21px;
  height: 21px;
  border: 1px solid #eee;
  border-radius: 50%;
  text-align: center;
  vertical-align: text-top;
  margin-top: -3px;
  position: relative;
  top: 2px;
  margin-left: 5px;
  line-height: 20px;
}

.stats-arrow-up {
  color: #4CAF50;
  border-color: #6ec071;
}

.stats-arrow-down {
  color: #f1453d;
  border-color: #f4736d;
}

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}

.ie-warning p {
  font-size: 17px;
}

.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}

.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}

.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}

.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}

.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}

.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

#footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 110px;
  color: #a2a2a2;
  padding-top: 35px;
  padding-bottom: 15px;
}

@media (min-width: 1280px) {
  #footer {
    padding-left: 268px;
  }
}
#footer .f-menu {
  display: block;
  width: 100%;
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  margin-top: 8px;
}

#footer .f-menu > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

#footer .f-menu > li > a {
  color: #a2a2a2;
}

#footer .f-menu > li > a:hover {
  color: #777;
}

.sidebar-toggled #footer {
  display: none;
}

.pt-inner {
  text-align: center;
}

.pt-inner .pti-header {
  padding: 45px 10px 70px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
}

.pt-inner .pti-header > h2 {
  margin: 0;
  line-height: 100%;
  color: #fff;
  font-weight: 100;
  font-size: 50px;
}

.pt-inner .pti-header > h2 small {
  color: #fff;
  letter-spacing: 0;
  vertical-align: top;
  font-size: 16px;
  font-weight: 100;
}

.pt-inner .pti-header .ptih-title {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 8px 10px 9px;
  text-transform: uppercase;
  margin: 0 -10px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.pt-inner .pti-body {
  padding: 0 23px;
}

.pt-inner .pti-body .ptib-item {
  padding: 15px 0;
  font-weight: 400;
}

.pt-inner .pti-body .ptib-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.pt-inner .pti-footer {
  padding: 10px 20px 30px;
}

.pt-inner .pti-footer > a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 60px;
  font-size: 30px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.pt-inner .pti-footer > a:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

.invoice {
  min-width: 1100px;
  max-width: 1170px;
}

.i-logo {
  width: 150px;
}

.i-table .highlight {
  background-color: #eee;
  border-bottom: 1px solid #e6e6e6;
}

.i-table td.highlight {
  font-size: 14px;
  font-weight: 500;
}

/*-------------------------------
    Post
--------------------------------*/
.wp-text {
  border: 0;
  display: block;
  width: 100%;
  resize: none !important;
  padding: 20px 25px;
}

.wp-media {
  background: #F7F7F7;
  border: 1px solid #E4E4E4;
  padding: 12px 15px;
  margin: 10px 20px 20px;
  text-align: center;
}

.wp-actions {
  padding: 10px 15px 10px 20px;
  background-color: #F7F7F7;
}

.wpa-media-list > a {
  font-size: 20px;
  margin-right: 8px;
}

.wpa-media-list > a:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}

/*-------------------------------
    Post Listings
--------------------------------*/
.wis-numbers {
  float: left;
}

.wis-numbers > span {
  margin-right: -1px;
  padding: 7px 12px;
  border: 1px solid #E0E0E0;
  float: left;
  font-weight: 500;
}

.wis-numbers > span > i {
  line-height: 100%;
  vertical-align: top;
  position: relative;
  top: 3px;
  font-size: 15px;
  margin-right: 2px;
}

.wis-numbers > span.active {
  color: #4CAF50;
}

.wis-commentors {
  float: right;
}

.wis-commentors > a {
  display: inline-block;
  margin-left: 2px;
}

.wis-commentors > a > img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.wis-commentors > a > img:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

/*-------------------------------
   Post Gallery
--------------------------------*/
.wi-preview {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .wi-preview {
    margin: 0 -23px 20px;
  }
}
@media screen and (max-width: 991px) {
  .wi-preview {
    margin: 0 -16px 20px;
  }
}
.wi-preview .wip-item {
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.wi-preview .wip-item:hover {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.wi-preview .wip-item > img {
  display: none;
}

.wi-preview .wip-item:first-child:nth-last-child(2),
.wi-preview .wip-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
  padding-bottom: 40%;
}

.wi-preview .wip-item:first-child:nth-last-child(3),
.wi-preview .wip-item:first-child:nth-last-child(3) ~ div,
.wi-preview .wip-item:first-child:nth-last-child(4),
.wi-preview .wip-item:first-child:nth-last-child(4) ~ div:not(:last-child),
.wi-preview .wip-item:first-child:nth-last-child(5),
.wi-preview .wip-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
.wi-preview .wip-item:first-child:nth-last-child(6),
.wi-preview .wip-item:first-child:nth-last-child(6) ~ div,
.wi-preview .wip-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3) {
  width: 33.333333%;
  padding-bottom: 30%;
}

.wi-preview .wip-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%;
  padding-bottom: 40%;
}

.wi-preview .wip-item:first-child:nth-last-child(7),
.wi-preview .wip-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
.wi-preview .wip-item:first-child:nth-last-child(n+8),
.wi-preview .wip-item:first-child:nth-last-child(n+8) ~ div {
  width: 25%;
  padding-bottom: 22%;
}

.wi-preview .wip-item:only-child,
.wi-preview .wip-item:first-child:nth-last-child(4) ~ div:nth-child(4) {
  width: 100%;
  padding-bottom: 50%;
}

/*-------------------------------
   Post Comments
--------------------------------*/
.wi-comments {
  background: #F7F7F7;
}

.wi-comments .list-group {
  margin-bottom: -10px;
  padding-top: 10px;
}

.wic-form {
  padding: 20px 23px;
}

.wic-form textarea {
  width: 100%;
  resize: none;
  border: 1px solid #E8E8E8;
  padding: 12px 15px;
  height: 45px;
}

.wic-form.toggled textarea {
  height: auto;
}

.wic-form.toggled .wicf-actions {
  display: block;
}

.wicf-actions {
  margin-top: 10px;
  display: none;
}

/*----------------------------------------------------------
    For header type 1 only
    You may remove these if you opt header 2
-----------------------------------------------------------*/
#header .skin-switch {
  padding: 10px 0 2px;
  text-align: center;
}

#header .ss-skin {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 2px 3px;
}

/* ----------------------------- End header type 1 ------------------------------------- */
/*----------------------------------------------------------
    For header type 2 only
    You may remove these if you opt header 1
-----------------------------------------------------------*/
@media (min-width: 992px) {
  #header-alt .skin-switch {
    position: absolute;
    right: 50px;
    bottom: 23px;
    z-index: 1;
  }

  #header-alt .skin-switch .btn {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 25px;
    z-index: 2;
  }

  #header-alt .skin-switch .dropdown-menu {
    display: block;
    min-width: 130px;
    height: 130px;
    border-radius: 50%;
    width: 130px;
    top: -42px;
    left: -40px;
    z-index: 1;
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scale(0) rotate(-360deg);
    -ms-transform: scale(0) rotate(-360deg);
    -o-transform: scale(0) rotate(-360deg);
    transform: scale(0) rotate(-360deg);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin {
    position: absolute;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-1 {
    margin-left: -8px;
    top: 12px;
    left: 50%;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-2 {
    right: 24px;
    top: 26px;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-3 {
    top: 50%;
    margin-top: -8px;
    right: 12px;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-4 {
    right: 24px;
    bottom: 26px;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-5 {
    margin-left: -8px;
    bottom: 12px;
    left: 50%;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-6 {
    left: 24px;
    bottom: 26px;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-7 {
    top: 50%;
    margin-top: -8px;
    left: 12px;
  }

  #header-alt .skin-switch .dropdown-menu .ss-skin.ss-8 {
    left: 24px;
    top: 26px;
  }

  #header-alt .skin-switch.open .dropdown-menu {
    -webkit-transform: scale(1) rotate(0deg);
    -ms-transform: scale(1) rotate(0deg);
    -o-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
  }
}
@media (max-width: 991px) {
  #header-alt .skin-switch {
    display: none;
  }
}
/* ----------------------------- End header type 2 ------------------------------------- */
/*-----------------------------------------------------------
    Do not remove these
    This is common for both
-----------------------------------------------------------*/
.ss-skin {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}

.ss-skin:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

[data-ma-theme=lightblue] {
  background-color: #03A9F4;
}

[data-ma-theme=lightblue] .ss-icon {
  color: #03A9F4;
}

@media (max-width: 767px) {
  [data-ma-theme=lightblue] .ha-menu {
    background: #03A9F4;
  }
}
[data-ma-theme=bluegray] {
  background-color: #607D8B;
}

[data-ma-theme=bluegray] .ss-icon {
  color: #607D8B;
}

@media (max-width: 767px) {
  [data-ma-theme=bluegray] .ha-menu {
    background: #607D8B;
  }
}
[data-ma-theme=blue] {
  background-color: #2196F3;
}

[data-ma-theme=blue] .ss-icon {
  color: #2196F3;
}

@media (max-width: 767px) {
  [data-ma-theme=blue] .ha-menu {
    background: #2196F3;
  }
}
[data-ma-theme=purple] {
  background-color: #BA68C8;
}

[data-ma-theme=purple] .ss-icon {
  color: #BA68C8;
}

@media (max-width: 767px) {
  [data-ma-theme=purple] .ha-menu {
    background: #BA68C8;
  }
}
[data-ma-theme=orange] {
  background-color: #FF9800;
}

[data-ma-theme=orange] .ss-icon {
  color: #FF9800;
}

@media (max-width: 767px) {
  [data-ma-theme=orange] .ha-menu {
    background: #FF9800;
  }
}
[data-ma-theme=cyan] {
  background-color: #00BCD4;
}

[data-ma-theme=cyan] .ss-icon {
  color: #00BCD4;
}

@media (max-width: 767px) {
  [data-ma-theme=cyan] .ha-menu {
    background: #00BCD4;
  }
}
[data-ma-theme=green] {
  background-color: #4CAF50;
}

[data-ma-theme=green] .ss-icon {
  color: #4CAF50;
}

@media (max-width: 767px) {
  [data-ma-theme=green] .ha-menu {
    background: #4CAF50;
  }
}
[data-ma-theme=teal] {
  background-color: #009688;
}

[data-ma-theme=teal] .ss-icon {
  color: #009688;
}

@media (max-width: 767px) {
  [data-ma-theme=teal] .ha-menu {
    background: #009688;
  }
}
[data-ma-theme=pink] {
  background-color: #E91E63;
}

[data-ma-theme=pink] .ss-icon {
  color: #E91E63;
}

@media (max-width: 767px) {
  [data-ma-theme=pink] .ha-menu {
    background: #E91E63;
  }
}
.preloader {
  position: relative;
  margin: 0px auto;
  display: inline-block;
}

.preloader:not([class*=pl-]) {
  width: 40px;
}

.preloader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.preloader.pl-xs {
  width: 20px;
}

.preloader.pl-sm {
  width: 30px;
}

.preloader.pl-lg {
  width: 50px;
}

.preloader.pl-xl {
  width: 80px;
}

.preloader.pl-xxl {
  width: 100px;
}

.preloader:not([class*=pls-]) .plc-path {
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
}

.preloader[class*=pls-] .plc-path {
  animation: dash 1.5s ease-in-out infinite;
}

.preloader.pls-red .plc-path {
  stroke: #f1453d;
}

.preloader.pls-blue .plc-path {
  stroke: #2196F3;
}

.preloader.pls-green .plc-path {
  stroke: #4CAF50;
}

.preloader.pls-yellow .plc-path {
  stroke: #607d8b;
}

.preloader.pls-bluegray .plc-path {
  stroke: #607D8B;
}

.preloader.pls-amber .plc-path {
  stroke: #FFC107;
}

.preloader.pls-teal .plc-path {
  stroke: #009688;
}

.preloader.pls-gray .plc-path {
  stroke: #9E9E9E;
}

.preloader.pls-pink .plc-path {
  stroke: #E91E63;
}

.preloader.pls-purple .plc-path {
  stroke: #BA68C8;
}

.preloader.pls-white .plc-path {
  stroke: #fff;
}

.pl-circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.plc-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #f1453d;
  }
  40% {
    stroke: #2196F3;
  }
  66% {
    stroke: #4CAF50;
  }
  80%, 90% {
    stroke: #FFC107;
  }
}
/*------------------------------
    Next/Prev Months
-------------------------------*/
.actions-calendar .calendar-next > i,
.actions-calendar .calendar-prev > i {
  font-size: 25px;
  position: relative;
  top: 2px;
}

/*------------------------------
    Tag color
-------------------------------*/
.event-tag > span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 0 3px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.event-tag > span.color-tag__default {
  border: 1px solid #eee;
  background-color: #fff !important;
}

.event-tag > span.color-tag__default > i:before {
  color: #333;
}

.event-tag > span,
.event-tag > span > i {
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

.event-tag > span > input[type=radio] {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.event-tag > span > input[type=radio]:checked + i {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.event-tag > span > i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 4px 0 0 7px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.event-tag > span > i:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  color: #fff;
  font-size: 16px;
  z-index: 1;
}

/*-------------------------------------
    Main Calendar Seasonal headers
--------------------------------------*/
[data-calendar-month] {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

[data-calendar-month="0"] {
  background-image: asset-url("calendar/january.jpg");
}

[data-calendar-month="1"] {
  background-image: asset-url("calendar/february.jpg");
}

[data-calendar-month="2"] {
  background-image: asset-url("calendar/march.jpg");
}

[data-calendar-month="3"] {
  background-image: asset-url("calendar/april.jpg");
}

[data-calendar-month="4"] {
  background-image: asset-url("calendar/may.jpg");
}

[data-calendar-month="5"] {
  background-image: asset-url("calendar/june.jpg");
}

[data-calendar-month="6"] {
  background-image: asset-url("calendar/july.jpg");
}

[data-calendar-month="7"] {
  background-image: asset-url("calendar/august.jpg");
}

[data-calendar-month="8"] {
  background-image: asset-url("calendar/september.jpg");
}

[data-calendar-month="9"] {
  background-image: asset-url("calendar/october.jpg");
}

[data-calendar-month="10"] {
  background-image: asset-url("calendar/november.jpg");
}

[data-calendar-month="11"] {
  background-image: asset-url("calendar/december.jpg");
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }
  body {
    margin: 0mm 0mm 0mm 0mm !important;
    padding: 0mm !important;
  }

  #header,
#footer,
#sidebar,
#chat,
.growl-animated,
.m-btn {
    display: none !important;
  }

  /*-------------------------
        Invoice
     --------------------------*/
  .invoice {
    padding: 30px !important;
    -webkit-print-color-adjust: exact !important;
  }

  .invoice .card-header {
    background: #eee !important;
    padding: 20px;
    margin-bottom: 20px;
    margin: -60px -30px 25px -30px;
  }

  .invoice .block-header {
    display: none;
  }

  .invoice .highlight {
    background: #eee !important;
  }
}
.visitors-stats {
  margin: 0 -6px 30px;
}

.visitors-stats .col-xs-6 {
  padding: 0 6px;
}

.visitors-stats-item {
  border: 1px solid #eee;
  padding: 13px 17px 12px;
  border-radius: 2px;
}

.visitors-stats-item strong {
  font-size: 25px;
  font-weight: normal;
  color: #333;
  line-height: 100%;
}

.visitors-stats-item small {
  display: block;
  margin-top: 3px;
  color: #777777;
}

.analytics-img-country {
  height: 13px;
  width: 18px;
  vertical-align: top;
  position: relative;
  top: 2px;
  margin-right: 5px;
  left: -1px;
  border-radius: 1px;
}

.analytics-img-browser {
  width: 20px;
  vertical-align: top;
  margin: -2px 10px 0 0;
  top: 1px;
  position: relative;
}

/*
 * Vendor Overrides
 */
.mejs-container {
  outline: none;
}

.mejs-container .mejs-controls {
  background: #ec592f;
  height: 50px;
  padding: 10px 5px 0;
}

.mejs-container .mejs-controls div {
  height: 5px;
}

.mejs-container .mejs-controls div.mejs-time-rail {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  width: 100% !important;
}

.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-total {
  margin: 0;
  width: 100% !important;
  background: #ec592f;
}

.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-loaded {
  background: #D04B25;
}

.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-current {
  background: #ffea00;
}

.mejs-container .mejs-controls div.mejs-time-rail .mejs-time-buffering {
  background: #ec592f;
}

.mejs-container .mejs-controls div.mejs-time-rail span:not(.mejs-time-float),
.mejs-container .mejs-controls div.mejs-time-rail a {
  border-radius: 0;
  height: 3px;
}

.mejs-container .mejs-controls .mejs-button button {
  background-color: #ec592f;
  width: 15px;
  height: 15px;
  background-position: center;
}

.mejs-container .mejs-controls .mejs-button button:focus {
  outline: none !important;
}

.mejs-container .mejs-controls .mejs-volume-button {
  position: absolute;
  right: 35px;
}

.mejs-container .mejs-controls .mejs-play button {
  background-image: url("/assets/icons/play.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-play button {
    background-image: url("/assets/icons/play@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-pause button {
  background-image: url("/assets/icons/pause.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-pause button {
    background-image: url("/assets/icons/pause@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-mute button {
  background-image: url("/assets/icons/speaker.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-mute button {
    background-image: url("/assets/icons/speaker@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-unmute button {
  background-image: url("/assets/icons/speaker-2.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-unmute button {
    background-image: url("/assets/icons/speaker-2@2x.png");
    background-size: 15px 15px;
  }
}
.mejs-container .mejs-controls .mejs-fullscreen-button {
  position: absolute;
  right: 5px;
}

.mejs-container .mejs-controls .mejs-fullscreen-button button {
  background-image: url("/assets/icons/fullscreen.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mejs-container .mejs-controls .mejs-fullscreen-button button {
    background-image: url("/assets/icons/fullscreen@2x.png");
    background-size: 15px 15px;
  }
}
.fc th {
  font-weight: 500;
  padding: 12px 12px 10px;
  text-transform: uppercase;
}

.fc table {
  background: transparent;
}

.fc table tr > td:first-child {
  border-left-width: 0;
}

.fc button {
  border: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  line-height: 28px;
  display: block;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  color: #333;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.fc button:hover {
  background-color: #eee;
}

.fc div.fc-row {
  margin-right: 0;
  border: 0;
}

.fc-unthemed .fc-today {
  color: #FFC107;
  background-color: transparent;
}

.fc-toolbar {
  margin-bottom: 0;
  padding: 15px 20px;
  position: relative;
}

.fc-toolbar h2 {
  margin-top: 7px;
}

.fc-event {
  padding: 0;
  font-size: 11px;
  border-radius: 2px;
  border: 0;
}

.fc-event .fc-title {
  padding: 2px 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-event .fc-time {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  margin: 0 0 0 -1px;
}

.fc-view,
.fc-view > table {
  border: 0;
  overflow: hidden;
}

.fc-view > table > tbody > tr .ui-widget-content {
  border-top: 0;
}

hr.fc-divider {
  border-width: 1px;
  border-color: #eee;
}

.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow: hidden !important;
}

/*----------------------
    Main Calendar
-----------------------*/
#calendar {
  z-index: 0;
}

#calendar td,
#calendar th {
  border-color: #f5f5f5;
}

#calendar .fc-toolbar {
  height: 250px;
  background-color: #fff;
  border-radius: 2px 2px 0 0;
  position: relative;
  margin-bottom: -2px;
  z-index: 2;
}

@media (max-width: 767px) {
  #calendar .fc-toolbar {
    height: 135px;
  }
}
#calendar .fc-day-number {
  padding-left: 10px;
  color: #ccc;
  text-align: left;
  float: left;
}

@media screen and (min-width: 991px) {
  #calendar .fc-day-number {
    font-size: 20px;
    letter-spacing: -2px;
  }
}
#calendar .fc-day-header {
  text-align: left;
}

#calendar .fc-day-grid-event {
  margin: 1px 9px 0;
}

/*----------------------
    Calendar Widget
-----------------------*/
#calendar-widget td,
#calendar-widget th {
  border-color: transparent;
}

#calendar-widget .fc-toolbar h2 {
  font-size: 16px;
}

#calendar-widget .fc-day-number {
  text-align: center;
  width: 100%;
}

#calendar-widget .fc-day-grid-event {
  margin: 1px 3px 1px;
}

#calendar-widget .ui-widget-header th,
#calendar-widget .ui-widget-header {
  border-width: 0;
}

table.dataTable {
  margin: 20px 0;
}

table.dataTable thead [class*=sorting] {
  background-position: center right !important;
}

table.dataTable thead td {
  background: transparent !important;
  white-space: nowrap;
}

table.dataTable thead span.sort-icon {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 6px;
  width: 16px;
  height: 16px;
}

table.dataTable thead .sorting span {
  background: url("http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_both.png") no-repeat center right;
}

table.dataTable thead .sorting_asc span {
  background: url("http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc.png") no-repeat center right;
}

table.dataTable thead .sorting_desc span {
  background: url("http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc.png") no-repeat center right;
}

table.dataTable thead .sorting_asc_disabled span {
  background: url("http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc_disabled.png") no-repeat center right;
}

table.dataTable thead .sorting_desc_disabled span {
  background: url("http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc_disabled.png") no-repeat center right;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #F5F5F5;
}

.dataTables_length,
.dataTables_filter {
  padding: 20px 30px;
}

.dataTables_length label,
.dataTables_filter label {
  font-weight: normal;
}

.dataTables_length input,
.dataTables_filter input,
.dataTables_length select,
.dataTables_filter select {
  border: 1px solid #eee;
  height: 35px;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: 100%;
  background-color: #fff;
}

.dataTables_length select {
  margin: 0 8px;
}

@media (max-width: 767px) {
  .dataTables_length {
    display: none;
  }
}
.dataTables_filter label {
  position: relative;
  font-size: 0;
}

.dataTables_filter label:after {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  font-size: 18px;
  right: 15px;
  top: 5px;
}

.dataTables_filter label .form-control {
  padding-right: 35px;
  width: 100% !important;
  margin: 0 !important;
}

@media (max-width: 767px) {
  .dataTables_filter label {
    width: 100%;
  }
}
.dataTables_wrapper .dataTables_filter {
  width: 40%;
}

@media (max-width: 767px) {
  .dataTables_wrapper .dataTables_filter {
    width: 100%;
  }
}
.dataTables_wrapper .dataTables_filter input {
  margin: 0 !important;
}

.dataTables_wrapper .dataTables_filter label,
.dataTables_wrapper .dataTables_filter input {
  width: 100%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f1f1f1;
  vertical-align: top;
  color: #7E7E7E !important;
  margin: 0 2px;
  border: 0 !important;
  line-height: 21px;
  box-shadow: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: #e4e4e4;
  color: #7E7E7E !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  background: #58d6e6;
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-size: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  font-size: 20px;
  line-height: 35px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:before {
  content: "";
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:before {
  content: "";
}

.dataTables_info,
.dataTables_paginate {
  padding: 30px;
}

@media (max-width: 767px) {
  .dataTables_info {
    display: none;
  }
}
.bootstrap-select {
  width: 100%;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select .bs-caret {
  display: none;
}

.bootstrap-select > .btn-default {
  background: none !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.bootstrap-select > .btn-default:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .bootstrap-select > .btn-default:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
.bootstrap-select .bs-searchbox {
  padding: 5px 5px 5px 40px;
  position: relative;
  background: #F7F7F7;
  margin-top: -10px;
}

.bootstrap-select .bs-searchbox:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  content: "";
  font-family: "Material-Design-Iconic-Font";
  font-size: 25px;
  padding: 4px 0 0 15px;
}

.bootstrap-select .bs-searchbox input {
  border: 0;
  background: transparent;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  padding-left: 17px;
}

.bootstrap-select.btn-group .dropdown-menu .no-results {
  padding: 8px 8px 0;
  background-color: #fff;
}

.bootstrap-select .check-mark {
  margin-top: -5px !important;
  font-size: 19px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  display: block !important;
  position: absolute;
  top: 11px;
  right: 15px;
}

.bootstrap-select .check-mark:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
}

.bootstrap-select .selected .check-mark {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.bootstrap-select .notify {
  bottom: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background: #f1453d !important;
  color: #fff !important;
  text-align: center;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.chosen-container .chosen-drop {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1px;
  border: 0;
  top: 0;
  border-radius: 2px;
}

.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  max-height: 300px;
}

.chosen-container .chosen-results li {
  padding: 10px 17px;
  width: 100%;
}

.chosen-container .chosen-results li.highlighted {
  background: rgba(0, 0, 0, 0.075);
  color: #333;
}

.chosen-container .chosen-results li.result-selected {
  background: transparent;
  color: #5E5E5E;
  position: relative;
}

.chosen-container .chosen-results li.result-selected:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 19px;
}

.chosen-container .chosen-results li.group-result {
  color: #B2B2B2;
  font-weight: normal;
  padding: 16px 15px 6px;
  margin-top: 9px;
}

.chosen-container .chosen-results li.group-result:not(:first-child) {
  border-top: 1px solid #eee;
}

.chosen-container-single .chosen-single {
  border-radius: 0;
  overflow: visible;
  height: 34px;
  padding: 6px 0 6px;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  background: none;
  box-shadow: none;
}

.chosen-container-single .chosen-single:before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  height: calc(100% - 2px);
  width: 30px;
  background-color: #FFF;
  background-position: right calc(100% - 7px);
  background-repeat: no-repeat;
  background-image: url("/img/select.png");
  pointer-events: none;
  z-index: 5;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .chosen-container-single .chosen-single:before {
    background-image: url("/assets/select@2x.png");
    background-size: 12px 12px;
  }
}
.chosen-container-single .chosen-single div b {
  display: none;
}

.chosen-container-single .chosen-search {
  padding: 5px 5px 5px 40px;
  background: #F7F7F7;
}

.chosen-container-single .chosen-search:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  font-size: 25px;
  padding: 5px 0 0 15px;
}

.chosen-container-single .chosen-search input[type=text] {
  border: 0;
  height: 35px;
  line-height: 1.42857143;
  background-image: none !important;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border: 0;
  background: none;
}

.chosen-container-multi .chosen-choices {
  padding: 0;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  background: none;
  box-shadow: none;
}

.chosen-container-multi .chosen-choices li.search-choice {
  border-radius: 2px;
  margin: 4px 4px 0 0;
  background: #eaeaea;
  padding: 5px 23px 5px 8px;
  border: 0;
  box-shadow: none;
  font-size: 12px;
}

.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  padding: 0;
  height: 31px;
}

.search-choice-close {
  cursor: pointer;
}

.search-choice-close:before {
  display: inline-block;
  font-family: "Material-Design-Iconic-Font";
  content: "";
  position: relative;
  top: 1px;
  color: #9C9C9C;
  z-index: 2;
  background: #EAEAEA;
  font-size: 12px;
}

.noUi-target {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

.noUi-background {
  background: #d4d4d4;
  box-shadow: none;
}

.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  top: -8px;
}

.noUi-vertical {
  width: 3px;
}

.noUi-connect {
  background: #009688;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  width: 19px;
  height: 19px;
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  -webkit-transition: box-shadow;
  -o-transition: box-shadow;
  transition: box-shadow;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  cursor: pointer;
  position: relative;
  background-color: #009688;
}

.noUi-horizontal .noUi-handle:before,
.noUi-vertical .noUi-handle:before,
.noUi-horizontal .noUi-handle:after,
.noUi-vertical .noUi-handle:after {
  display: none;
}

.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.1);
}

.noUi-tooltip {
  border: 0;
  background: #d4d4d4;
  padding: 5px 10px;
}

.cp-container {
  position: relative;
}

.cp-container > .input-group input.cp-value {
  color: #000 !important;
  background: transparent !important;
}

.cp-container > .input-group .dropdown-menu {
  padding: 20px;
}

.cp-container i.cp-value {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 15px;
}

.note-editor .note-toolbar,
.note-popover .note-toolbar,
.note-editor .popover-content,
.note-popover .popover-content {
  background: #fff;
  border-color: #e4e4e4;
  margin: 0;
  padding: 10px 0 15px;
  text-align: center;
}

.note-editor .note-toolbar > .btn-group,
.note-popover .note-toolbar > .btn-group,
.note-editor .popover-content > .btn-group,
.note-popover .popover-content > .btn-group {
  display: inline-block;
  float: none;
  box-shadow: none;
}

.note-editor .note-toolbar > .btn-group .btn,
.note-popover .note-toolbar > .btn-group .btn,
.note-editor .popover-content > .btn-group .btn,
.note-popover .popover-content > .btn-group .btn {
  margin: 0 1px;
}

.note-editor .note-toolbar > .btn-group > .active,
.note-popover .note-toolbar > .btn-group > .active,
.note-editor .popover-content > .btn-group > .active,
.note-popover .popover-content > .btn-group > .active {
  background: #00BCD4;
  color: #fff;
}

.note-editor .note-toolbar .btn,
.note-popover .note-toolbar .btn,
.note-editor .popover-content .btn,
.note-popover .popover-content .btn {
  height: 40px;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.note-editor .note-toolbar .btn:active,
.note-popover .note-toolbar .btn:active,
.note-editor .popover-content .btn:active,
.note-popover .popover-content .btn:active {
  box-shadow: none;
}

.note-editor .note-toolbar .note-palette-title,
.note-popover .note-toolbar .note-palette-title,
.note-editor .popover-content .note-palette-title,
.note-popover .popover-content .note-palette-title {
  margin: 0 !important;
  padding: 10px 0 !important;
  font-size: 13px !important;
  text-align: center !important;
  border: 0 !important;
}

.note-editor .note-toolbar .note-color-reset,
.note-popover .note-toolbar .note-color-reset,
.note-editor .popover-content .note-color-reset,
.note-popover .popover-content .note-color-reset {
  padding: 0 0 10px !important;
  margin: 0 !important;
  background: none;
  text-align: center;
}

.note-editor .note-toolbar .note-color .dropdown-menu,
.note-popover .note-toolbar .note-color .dropdown-menu,
.note-editor .popover-content .note-color .dropdown-menu,
.note-popover .popover-content .note-color .dropdown-menu {
  min-width: 335px;
}

.note-editor .note-statusbar .note-resizebar,
.note-popover .note-statusbar .note-resizebar {
  border-color: #E8E8E8;
}

.note-editor .note-statusbar .note-resizebar .note-icon-bar,
.note-popover .note-statusbar .note-resizebar .note-icon-bar {
  border-color: #BCBCBC;
}

.note-editor .fa,
.note-popover .fa {
  font-style: normal;
  font-size: 20px;
  vertical-align: middle;
}

.note-editor .fa:before,
.note-popover .fa:before {
  font-family: "Material-Design-Iconic-Font";
}

.note-editor .fa.fa-magic:before,
.note-popover .fa.fa-magic:before {
  content: "";
}

.note-editor .fa.fa-bold:before,
.note-popover .fa.fa-bold:before {
  content: "";
}

.note-editor .fa.fa-italic:before,
.note-popover .fa.fa-italic:before {
  content: "";
}

.note-editor .fa.fa-underline:before,
.note-popover .fa.fa-underline:before {
  content: "";
}

.note-editor .fa.fa-font:before,
.note-popover .fa.fa-font:before {
  content: "";
}

.note-editor .fa.fa-list-ul:before,
.note-popover .fa.fa-list-ul:before {
  content: "";
}

.note-editor .fa.fa-list-ol:before,
.note-popover .fa.fa-list-ol:before {
  content: "";
}

.note-editor .fa.fa-align-left:before,
.note-popover .fa.fa-align-left:before {
  content: "";
}

.note-editor .fa.fa-align-right:before,
.note-popover .fa.fa-align-right:before {
  content: "";
}

.note-editor .fa.fa-align-center:before,
.note-popover .fa.fa-align-center:before {
  content: "";
}

.note-editor .fa.fa-align-justify:before,
.note-popover .fa.fa-align-justify:before {
  content: "";
}

.note-editor .fa.fa-indent:before,
.note-popover .fa.fa-indent:before {
  content: "";
}

.note-editor .fa.fa-outdent:before,
.note-popover .fa.fa-outdent:before {
  content: "";
}

.note-editor .fa.fa-text-height:before,
.note-popover .fa.fa-text-height:before {
  content: "";
}

.note-editor .fa.fa-table:before,
.note-popover .fa.fa-table:before {
  content: "";
}

.note-editor .fa.fa-link:before,
.note-popover .fa.fa-link:before {
  content: "";
}

.note-editor .fa.fa-picture-o:before,
.note-popover .fa.fa-picture-o:before {
  content: "";
}

.note-editor .fa.fa-minus:before,
.note-popover .fa.fa-minus:before {
  content: "";
}

.note-editor .fa.fa-arrows-alt:before,
.note-popover .fa.fa-arrows-alt:before {
  content: "";
}

.note-editor .fa.fa-code:before,
.note-popover .fa.fa-code:before {
  content: "";
}

.note-editor .fa.fa-question:before,
.note-popover .fa.fa-question:before {
  content: "";
}

.note-editor .fa.fa-eraser:before,
.note-popover .fa.fa-eraser:before {
  content: "";
}

.note-editor .fa.fa-square:before,
.note-popover .fa.fa-square:before {
  content: "";
}

.note-editor .fa.fa-circle-o:before,
.note-popover .fa.fa-circle-o:before {
  content: "";
}

.note-editor .fa.fa-times:before,
.note-popover .fa.fa-times:before {
  content: "";
}

.note-editor .note-air-popover .arrow,
.note-popover .note-air-popover .arrow {
  left: 20px;
}

.note-editor {
  overflow: visible;
  border: 1px solid #e4e4e4;
}

.note-editor .note-editable {
  padding: 20px 23px;
}

.bootstrap-datetimepicker-widget {
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
  bottom: auto !important;
  top: 0 !important;
}

.bootstrap-datetimepicker-widget:after,
.bootstrap-datetimepicker-widget:before {
  display: none !important;
}

.bootstrap-datetimepicker-widget table td {
  text-shadow: none;
}

.bootstrap-datetimepicker-widget table td span {
  margin: 0;
}

.bootstrap-datetimepicker-widget table td span:hover {
  background: transparent;
}

.bootstrap-datetimepicker-widget .glyphicon {
  font-family: "Material-Design-Iconic-Font";
  font-size: 18px;
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-left:before {
  content: "";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-right:before {
  content: "";
}

.bootstrap-datetimepicker-widget .glyphicon-time:before {
  content: "";
}

.bootstrap-datetimepicker-widget .glyphicon-calendar:before {
  content: "";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-up:before {
  content: "";
}

.bootstrap-datetimepicker-widget .glyphicon-chevron-down:before {
  content: "";
}

.bootstrap-datetimepicker-widget a[data-action] {
  color: #009688;
}

.timepicker-picker .btn {
  box-shadow: none !important;
}

.timepicker-picker table tbody tr + tr:not(:last-child) {
  background: #009688;
  color: #fff;
}

.timepicker-picker table tbody tr + tr:not(:last-child) td {
  border-radius: 0;
}

.timepicker-picker .btn,
.timepicker-picker .btn:hover {
  background: #fff;
  color: #333;
}

.datepicker table thead tr th {
  border-radius: 0;
  color: #757779;
}

.datepicker table thead tr th .glyphicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 29px;
}

.datepicker table thead tr th:hover .glyphicon {
  background: rgba(0, 0, 0, 0.2);
}

.datepicker table thead tr:first-child th {
  background: transparent;
  color: #000;
  padding: 20px 0;
}

.datepicker table thead tr:first-child th:hover {
  background: transparent;
}

.datepicker table thead tr:first-child th.picker-switch {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.datepicker table thead tr:last-child th {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
}

.datepicker table thead tr:last-child th:first-child {
  padding-left: 20px;
}

.datepicker table thead tr:last-child th:last-child {
  padding-right: 20px;
}

.datepicker table tbody tr:last-child td {
  padding-bottom: 25px;
}

.datepicker table tbody tr td:first-child {
  padding-left: 13px;
}

.datepicker table tbody tr td:last-child {
  padding-right: 13px;
}

.datepicker table td.day {
  width: 35px;
  height: 35px;
  line-height: 20px;
  color: #333;
  position: relative;
  padding: 0;
  background: transparent;
}

.datepicker table td.day:hover {
  background: none;
}

.datepicker table td.day:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: -33px;
  display: inline-block;
  background: transparent;
  position: static;
  text-shadow: none;
  border-bottom-color: transparent !important;
}

.datepicker table td.day.old,
.datepicker table td.day.new {
  color: #CDCDCD;
}

.datepicker table td:not(.today):not(.active):hover:before {
  background: #F0F0F0;
}

.datepicker table td.today {
  color: #333;
}

.datepicker table td.today:before {
  background-color: #E2E2E2;
}

.datepicker table td.active {
  color: #000;
  text-shadow: none;
}

.datepicker table td.active:before {
  background-color: #e0f7fa;
}

.datepicker table td.active:hover {
  text-shadow: none;
  color: #000;
}

.datepicker-months .month,
.datepicker-years .year,
.timepicker-minutes .minute,
.timepicker-hours .hour {
  border-radius: 50%;
}

.datepicker-months .month:not(.active):hover,
.datepicker-years .year:not(.active):hover,
.timepicker-minutes .minute:not(.active):hover,
.timepicker-hours .hour:not(.active):hover {
  background: #000;
}

.datepicker-months .month.active,
.datepicker-years .year.active,
.timepicker-minutes .minute.active,
.timepicker-hours .hour.active {
  background: #009688;
}

.timepicker-minutes .minute,
.timepicker-hours .hour {
  padding: 0;
}

.fileinput {
  position: relative;
  padding-right: 35px;
}

.fileinput .close {
  position: absolute;
  top: 5px;
  font-size: 12px;
  float: none;
  opacity: 1;
  font-weight: 500;
  border: 1px solid #ccc;
  width: 19px;
  text-align: center;
  height: 19px;
  line-height: 16px;
  border-radius: 50%;
  right: 0;
  visibility: hidden;
}

.fileinput .close:hover {
  background: #eee;
}

.fileinput .input-group-addon {
  padding: 0 10px;
  vertical-align: middle;
}

.fileinput .fileinput-preview {
  width: 200px;
  height: 150px;
  position: relative;
}

.fileinput .fileinput-preview img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -13px;
}

.fileinput .fileinput-preview:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.fileinput-exists .close {
  visibility: visible;
}

.lg-outer .lg-thumb-outer {
  background-color: #1D1D1D;
}

.lg-outer .lg-thumb-item {
  border-radius: 50%;
  width: 60px !important;
  display: inline-block;
  height: 60px;
  border: 0;
  float: none;
  margin: 0 5px;
}

.lg-outer .lg-thumb-item:hover {
  box-shadow: 0 0 0px 4px rgba(255, 255, 255, 0.1);
}

.lg-outer .lg-image {
  border-radius: 3px;
}

.lg-outer .lg-toogle-thumb {
  border-radius: 50%;
  color: #333;
  height: 51px;
  width: 51px;
  line-height: 41px;
  background-color: #fff;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}

.lg-outer .lg-toogle-thumb:hover {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  color: #000;
}

.lg-outer:not(.lg-thumb-open) .lg-toogle-thumb {
  top: -70px;
}

.lg-outer.lg-thumb-open .lg-toogle-thumb {
  top: -26px;
}

.lg-thumb.group {
  padding: 20px 0;
}

.lg-slide em {
  font-style: normal;
}

.lg-slide em h3 {
  color: #fff;
  margin-bottom: 5px;
}

.lg-slide .video-cont {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

.lg-outer .lg-item {
  background-image: none !important;
}

.lg-outer .lg-item:before {
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-right-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  -webkit-animation-name: loader;
  animation-name: loader;
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading > i {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
  border-left-color: #fff;
  border-style: solid;
}

.swal2-modal {
  border-radius: 2px;
  padding: 30px !important;
  font-family: roboto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.07);
}

.swal2-modal h2 {
  font-size: 16px;
  position: relative;
  z-index: 1;
  color: #333;
  line-height: inherit;
  margin: 0 0 5px;
  font-weight: 500;
}

.swal2-modal .swal2-icon {
  margin-top: 0;
  margin-bottom: 20px;
}

.swal2-modal .swal2-content {
  color: #777777;
  font-size: 13px;
  font-weight: normal;
}

.swal2-modal .styled {
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 2px;
  margin: 0 2px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: inherit;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.swal2-modal .styled:not(:hover) {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.swal2-modal hr {
  margin: 10px 0;
}

.swal2-container.in {
  background-color: rgba(0, 0, 0, 0.2);
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-menu {
  min-width: 200px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: none;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.twitter-typeahead .tt-menu.tt-open:not(.tt-empty) {
  display: block;
}

.twitter-typeahead .tt-suggestion {
  padding: 8px 17px;
  color: #333;
  cursor: pointer;
}

.twitter-typeahead .tt-suggestion:hover,
.twitter-typeahead .tt-cursor {
  background-color: rgba(0, 0, 0, 0.075);
}

.twitter-typeahead .tt-hint {
  color: #818181 !important;
}

.mCSB_scrollTools {
  width: 5px;
}

.mCSB_scrollTools .mCSB_dragger_bar {
  border-radius: 0 !important;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCSB_scrollTools.mCSB_scrollTools_vertical {
  margin: 0 !important;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  height: 10px;
}

html:not(.ie9) .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.4);
}

html:not(.ie9) .mCS-minimal-dark.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
  background: rgba(0, 0, 0, 0.4);
}

html.ie9 .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: #000000;
}

html.ie9 .mCS-minimal-dark.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
  background: #000000;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.dropzone {
  border: 0;
  background-color: #f3f3f3;
  border-radius: 2px;
}

.dropzone .dz-preview .dz-image {
  border-radius: 2px !important;
  border: 4px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.dropzone .dz-preview .dz-remove {
  position: absolute;
  top: -3px;
  right: -4px;
  z-index: 20;
  font-size: 0;
  width: 20px;
  height: 20px;
  background-color: #f1453d;
  border-radius: 50%;
}

.dropzone .dz-preview .dz-remove:hover {
  background-color: #ef2e25;
  text-decoration: none;
}

.dropzone .dz-preview .dz-remove:before {
  content: "";
  font-size: 11px;
  font-family: "Material-Design-Iconic-Font";
  color: #fff;
  font-weight: bold;
  line-height: 20px;
}

.dropzone .dz-message span {
  font-size: 20px;
  color: #b5b5b5;
  text-shadow: 0 1px 1px #fff;
  border: 2px solid #e4e4e4;
  padding: 15px 20px;
  display: inline-block;
  border-radius: 5px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.dropzone .dz-message span:before {
  content: "";
  font-family: "Material-Design-Iconic-Font";
  margin: -6px 10px 0 0;
  font-size: 25px;
  display: inline-block;
  position: relative;
  top: 2px;
}

.dropzone:hover .dz-message span {
  border-color: #d7d7d7;
  color: #a8a8a8;
}

.tempory-images img {
  width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.no-padding {
  padding: 0 !important;
}

.last {
  margin-bottom: 0;
}

.logo {
  width: 200px;
  margin: 0 auto 50px;
  text-align: center;
}

.logo img {
  width: 100%;
}

.form-group small {
  display: block;
  margin-bottom: 8px;
  color: #AEAEAE;
}

.form-header {
  margin: 40px 0 20px;
  line-height: 100%;
  font-size: 16px;
  font-weight: 400;
}

.form-header.first {
  margin-top: 0;
}

.btn {
  padding: 8px 20px;
}

.login-content {
  background: none;
}

.login-content button {
  margin-top: 10px;
  width: 100%;
}

.login-actions {
  margin-top: 30px;
}

.login-actions .checkbox {
  float: left;
  margin: 0;
  position: relative;
}

.login-actions a {
  float: right;
  color: #607d8b;
}

.fz-block {
  background: none;
}

.fz-block small {
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 20px;
}

.fz-block p {
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.slider-wrapper {
  max-width: 300px;
}

.hi-logo span {
  color: #757779;
}

.h-inner {
  padding: 17px 10px 17px 35px;
  text-align: center;
}

.ha-menu {
  padding: 0px 35px;
}

small {
  color: #6d7074 !important;
}

.wrapper-one {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.action-nav {
  float: right;
}

.action-nav button {
  margin-left: 10px;
}

.user-profile a img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-profile a:hover {
  background-color: none;
}

.back-arrow {
  font-size: 20px;
  border-right: 1px solid #dcddde;
}

.back-arrow a {
  display: block;
  height: 70px;
  padding: 20px 10px;
  color: #6c6f74;
}

th {
  border-color: #e2e2e2 !important;
}

td {
  border-color: #e2e2e2 !important;
  vertical-align: middle !important;
}

td figure {
  max-width: 15%;
  display: inline-block;
  vertical-align: middle;
  padding-right: 20px;
  margin-right: -5px;
}

td img {
  width: 100%;
  height: auto;
}

td .text-wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 86%;
  margin-right: -5px;
}

table {
  color: #333333;
  page-break-inside: auto;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

table a {
  color: #333333;
}

table .drl {
  color: #0faa42;
  font-style: normal;
}

table .queue,
table .message {
  text-align: center;
}

table .more {
  text-align: right;
}

table .more ul {
  margin-bottom: 0;
}

table .campaign {
  color: #000;
  font-weight: 700;
  width: 250px;
}

table .rank {
  width: 100px;
}

table .rank .star {
  margin-right: 5px;
}

table .now,
table .first,
table .change {
  width: 20px;
}

table .change {
  color: #0faa42;
}

table .result .dot {
  margin-right: 5px;
}

table .result small {
  display: block;
  text-transform: uppercase;
}

table div {
  display: table;
  vertical-align: middle;
}
table div.asset-actions {
  float: left;
}
table div.asset-text {
  margin-top: 4px;
}

table i.alerts {
  color: #fff;
  font-style: normal;
  background: #f1453d;
  padding: 1px 5px;
  border-radius: 2px;
  right: 7px;
  top: -3px;
  font-size: 10px;
  line-height: 15px;
}
table i.alerts a {
  color: inherit;
}

table i.zmdi-more-vert {
  font-size: 24px;
  line-height: normal;
}

/*table.asset-table {
  color: white;
  a {
    color: white;
  }
}*/
.dataTable th {
  border-bottom: 0 !important;
}

/* Cards
----------------------------------- */
.card.form {
  margin-bottom: 30px;
}

.card.form th {
  color: #000;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card.form td {
  font-weight: 400;
  color: #333333;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card.form .user-type {
  color: #6d7074;
}

.card.form table {
  border-bottom: 1px solid #e2e2e2 !important;
  margin-bottom: 30px;
}

.card.search {
  margin-bottom: 0;
}

.card.asset .card-header {
  background-color: #f7f7f7;
  min-height: 145px;
}

.card.asset .card-header small {
  display: block;
  margin-bottom: 10px;
}

.card.asset .card-header h2 {
  line-height: normal;
}

.card.asset.flagged .header-wrap {
  padding-left: 15px;
  position: relative;
}

.card.asset.flagged .header-wrap .dot {
  position: absolute;
  top: -18px;
  left: 34px;
}

.card.asset .card-header.collapse {
  min-height: 0;
  height: 0;
}

.card.keyword .card-header {
  background-color: #f7f7f7;
}

.card.keyword .card-header small {
  display: block;
  margin-bottom: 10px;
}

.card.keyword .card-header h2 {
  line-height: normal;
}

/* Forms
----------------------------------- */
.form-control {
  border-color: #e2e2e2;
}

.keywords-tag {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.keywords-tag li {
  background-color: #607D8B;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 2px;
  margin-top: 5px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 0;
}

.keywords-tag i {
  font-weight: 600;
  margin-left: 10px;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.form-footer .update-buttons {
  float: left;
}

.form-footer .update-buttons a {
  color: #607D8B;
}

.form-footer .archive {
  float: right;
}

.form-footer .archive a {
  color: #9fa1a3;
  padding-right: 0;
}

.form-footer a {
  font-weight: 500;
}

.bootstrap-select .btn {
  text-transform: none !important;
}

/* Clients & Campaigns
----------------------------------- */
.search-wrapper {
  margin-bottom: 30px;
}

.search .card-padding {
  padding: 10px 30px !important;
}

.search input {
  border: none;
}

.filter-options {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.filter-options li {
  background-color: #607D8B;
  color: #fff;
  display: inline-block;
  padding: 8px 15px;
  margin-right: 2px;
  margin-top: 5px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 0;
  font-size: 15px;
}

.filter-options i {
  font-weight: 600;
  margin-left: 10px;
  font-size: 17px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

/* Campaign
----------------------------------- */
.campaign .h-inner {
  padding: 17px 10px 17px 60px;
}

.graphs .card-header {
  padding-left: 15px;
  padding-right: 15px;
}

.graphs .card-body {
  padding: 0 15px 30px;
}

.stat {
  display: block;
  margin-bottom: 20px;
  font-size: 50px;
  font-weight: 300;
}

.stat .swing {
  font-size: 13px;
  color: #0faa42;
  font-weight: 400;
}

.campaign-info {
  border-top: 1px solid #e2e2e2;
  padding: 30px 15px 20px;
}
.campaign-info a {
  display: block;
}
.campaign-info a button {
  line-height: 1;
  padding: 6px;
  margin-top: 10px;
  font-size: 12px;
}

.campaign-info h5 {
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 0 5px;
}

.view-more {
  border-top: 1px solid #e2e2e2;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.source {
  text-transform: uppercase;
  color: #9fa1a3;
  display: block;
  margin-top: 5px;
}

.recent-notes textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  max-height: 100px;
  padding: 10px;
  border: 1px solid #e2e2e2;
}

.note {
  margin-bottom: 26px;
}

.note h2 {
  margin: 0;
  line-height: 100%;
  font-size: 16px;
  font-weight: 500;
}

.note small {
  display: block;
  margin-top: 8px;
  color: #AEAEAE;
  line-height: 160%;
}

.note .note-text {
  margin-left: 57px;
  margin-top: 26px;
}

.campaigns-keywords .tab-nav {
  margin-bottom: 30px;
}

.graph-wrapper {
  padding: 26px 30px;
}

.graph-wrapper .bar {
  margin-top: 20px;
}

.graph-wrapper .bar:first-child {
  margin-top: 0;
}

.graph-wrapper small {
  margin-bottom: 10px;
  display: block;
}

.bar {
  display: block;
}

.fa-bar-wrapper {
  background-color: #dadbda;
  display: flex !important;
}
.fa-bar-wrapper div {
  transition: width 1s;
  -webkit-transition: width 1s;
}
.fa-bar-wrapper div.squeezed {
  width: 0% !important;
}

.bar.tall .fa-bar-wrapper {
  height: 44px;
}

.pdf .bar.tall .fa-bar-wrapper {
  height: 30px;
}

.pdf .bar.tall .fa-bar-wrapper div {
  padding-top: 7px;
}
.pdf .bar.tall .fa-bar-wrapper div .squeezed {
  width: auto;
}

.pdf .bar.medium .fa-bar-wrapper {
  margin-bottom: 5px;
  margin-top: 5px;
}

.bar.tall .fa-bar-wrapper div {
  color: white;
  padding-top: 13px;
  overflow: hidden;
}

.bar.tall .fa-bar-wrapper div.neutral {
  color: black;
}

.bar.medium .fa-bar-wrapper {
  height: 25px;
}

.bar.medium .fa-bar-wrapper div {
  padding-top: 3px;
  color: white;
  overflow: hidden;
}

.bar.medium .fa-bar-wrapper div.neutral {
  color: black;
}

.bar .fa-bar-wrapper {
  height: 3px;
  display: block;
}

div.fa-bar-wrapper div {
  color: transparent;
  height: 100%;
  float: left;
  display: block;
  text-align: center;
  border-right: 1px solid white;
}

div.fa-bar-wrapper div.unfavorable {
  background: #f34141;
  margin-left: 0;
}

div.fa-bar-wrapper div.favorable {
  background: #52b760;
}

div.fa-bar-wrapper div.neutral {
  background: #dadada;
}

.wall .w-item .card-body {
  margin-left: 58px;
}

.star {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.star i {
  font-size: 20px !important;
  color: #e8e8e8;
}

.star i.gold {
  color: #ffbf1f;
}

.dot {
  display: inline-block;
  vertical-align: middle;
}

.dot i.unmoderated {
  font-size: 13px !important;
  color: #fff !important;
  border: 1px solid #adadad;
  border-radius: 13px;
  height: 13px;
}

.dot i {
  font-size: 13px !important;
  color: #e8e8e8 !important;
}

.dot i.green {
  color: #4caf50 !important;
}

.dot i.red {
  color: #f44336 !important;
}

.alert button {
  margin-top: 3px;
}

.alert i {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin-right: 10px;
  line-height: normal;
}

.table-pagination {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.card-outer {
  position: relative;
  padding-bottom: 55px;
}

.card-outer .card,
.card-outer .dataTables_wrapper {
  position: static !important;
}

tfoot {
  display: none;
}

.dataTables_paginate {
  padding: 20px 0px !important;
}

.dataTables_paginate .paginate_button {
  background: #d9d9d9 !important;
}

.dataTables_paginate .paginate_button.disabled {
  background: transparent !important;
}

.dataTables_paginate .paginate_button.current {
  background-color: #9d9d9d !important;
}

.dataTables_length {
  padding: 20px 0px !important;
}

.dataTables_length label {
  margin: 0 !important;
}

.thumbnail {
  padding: 0;
  border: none;
}

.fileinput {
  display: block;
  margin-bottom: 0;
}

.fileinput.fileinput-new .thumbnail {
  display: none;
}

.fileinput .fileinput-preview {
  width: auto;
  max-width: 200px;
  height: auto;
}

.fileinput .fileinput-preview img {
  margin-top: 0;
}

.calendar {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #e2e2e2;
  background: #fff;
}

.calendar input {
  border: none;
  padding: 7px 10px !important;
  background-image: url(/img/caret.png);
  background-position: 96% center;
  background-size: 23px 20px;
  background-repeat: no-repeat;
}

body {
  background-color: #ebebeb;
  font-family: "Proxima Nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact !important;
}

.page-break {
  page-break-before: always !important;
}

#flot-placeholder {
  height: 100%;
  width: 100%;
  min-width: 450px;
}

.campaigns-keywords td.result {
  display: flex;
  align-items: center;
}

.campaigns-reports .displayed-link {
  display: block;
  margin-left: 35px;
}

.content {
  padding: 50px 0;
}

.container {
  max-width: 100%;
}

.navbar {
  padding: 0;
  align-items: stretch;
}
.navbar .hi-logo {
  align-items: center;
  display: flex;
  padding-left: 10px;
}
.navbar .hi-logo .zmdi {
  margin-right: 10px;
}

.listview__attrs {
  flex: 1 100%;
  margin-top: 0.5rem;
  display: flex;
}
.listview__attrs > span {
  background-color: #FFF;
  border: 1px solid #e9ecef;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 100%;
  margin: 0.2rem 0.25rem 0.055rem 0;
  padding: 0.55rem 0.7rem;
}

.card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  width: 100%;
}
.card .card-header {
  background-color: transparent;
  border-bottom: none;
}
.card .card-body {
  padding: 0;
}

.campaigns-reports .asset.card {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.campaigns-reports .asset.card-body {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
}

.campaigns-keywords .asset-table .asset-text p {
  margin-bottom: 0;
}
.campaigns-keywords .asset-table .asset-text p small {
  color: #b9bec5 !important;
}

.graphs {
  display: flex;
}

.btn.active.favorable {
  background-color: #38c172;
}
.btn.active.neutral {
  background-color: var(--gray);
}
.btn.active.unfavorable {
  background-color: #e3342f;
}

.spinner {
  margin-top: 10px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #1592ff;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
td.status.active,
td.status button.btn {
  color: #4caf50;
}
td.status.crawling,
td.status button.btn.crawling {
  color: #03a9f4;
}
td.status.crawling .zmdi-refresh,
td.status button.btn.crawling .zmdi-refresh {
  animation: loading 1s linear infinite;
}
td.status.error,
td.status button.btn.error {
  color: #03a9f4;
}
td.status.error .zmdi-refresh::before,
td.status button.btn.error .zmdi-refresh::before {
  content: "";
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
.campaigns-reports-pdf {
  background-color: #fff !important;
  margin: 0;
  padding: 0;
}
.campaigns-reports-pdf a {
  color: #827552;
  text-decoration: none;
}
.campaigns-reports-pdf .navbar, .campaigns-reports-pdf .ha-menu {
  display: none;
}
.campaigns-reports-pdf .block-header {
  padding-top: 25mm;
}
.campaigns-reports-pdf .block-header h2 {
  font-size: 22px;
  font-weight: bold;
}
.campaigns-reports-pdf #flot-placeholder {
  min-width: 350px;
}
.campaigns-reports-pdf .table-data .card,
.campaigns-reports-pdf .table-data .card-body .table {
  background-color: #fff !important;
  border: none;
  box-shadow: none;
  margin: 0 !important;
  padding: 0 !important;
}
.campaigns-reports-pdf .table-data .card-header {
  border-top: 2px solid #dee2e6 !important;
  border-right: 2px solid #dee2e6 !important;
  border-left: 2px solid #dee2e6 !important;
}
.campaigns-reports-pdf .table-data .card-body {
  margin: 0;
  border: none;
}
.campaigns-reports-pdf .table {
  margin: 0 !important;
}
.campaigns-reports-pdf .table thead tr, .campaigns-reports-pdf .table tbody tr {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.campaigns-reports-pdf .table tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}
.campaigns-reports-pdf .table-suggested .card {
  margin-bottom: 50px !important;
  border-bottom: 2px solid #dee2e6;
}
.campaigns-reports-pdf .page-break {
  page-break-before: always !important;
  break-before: always !important;
}
.campaigns-reports-pdf .print-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.campaigns-reports-pdf .print-header img {
  height: auto;
  width: 100%;
  max-width: 100%;
}
.campaigns-reports-pdf .print-header img.hr-logo {
  max-width: 25%;
}
.campaigns-reports-pdf .print-header img.hn-logo {
  max-width: 10%;
}
.campaigns-reports-pdf .footer {
  display: block;
  position: fixed;
  width: 100%;
  bottom: 25mm;
  left: 0;
  right: 0;
}
.campaigns-reports-pdf .footer .footer--content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
  border-top: 1px solid #817453;
  padding: 25px 15px 0;
}
.campaigns-reports-pdf .footer .footer--content div {
  color: #817453;
}
.campaigns-reports-pdf .no-border {
  border: none !important;
}
.campaigns-reports-pdf .footer-repeat {
  display: table-footer-group;
}

@page {
  size: A4;
  margin: 25mm 25mm 0 25mm;
  .paged {
    counter-increment: page;
    content: "Page " counter(page);
  }

  @bottom-right {
    content: counter(page) " of " counter(pages);
  }
}
@media print {
  .campaigns-reports .content {
    padding: 0;
  }
  .campaigns-reports .content table {
    page-break-inside: auto;
  }
  .campaigns-reports .content tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .campaigns-reports .content thead {
    display: table-header-group;
  }
  .campaigns-reports .content tfoot {
    display: table-footer-group;
  }
}